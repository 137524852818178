export const state = () => ({
  photos: [],
  films: [],
  logos: [],
  info: {},
});

export const mutations = {
  addMedia(state, { type, obj, media }) {
    const index = state[type].indexOf(obj);
    state[type][index].media = media;
  },
  addCover(state, { type, obj, cover }) {
    const index = state[type].indexOf(obj);
    state[type][index].cover = cover;
  },
  pushProjects(state, { photos, films, logos }) {
    state.photos = photos;
    state.films = films;
    state.logos = logos;
  },
  addInfo(state, info) {
    state.info = info;
  },
};
