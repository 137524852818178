<template>
  <div v-if="!isTouch()" ref="cursor" class="cursor" :class="{show: showCursor, text: showText}">
    <transition @beforeEnter="beforeEnterText" @enter="enterText" @leave="leaveText">
      <div v-show="showText" class="text">
        <div class="span-lol">
          {{ cursorTitle }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { TweenMax, TweenLite, TimelineMax, Power4 } from 'gsap';
import Util from '../assets/js/util';

export default {
  data: () => ({
    isLink: false,
    showCursor: false,
    cursorTitle: null,
  }),
  computed: {
    showText() {
      return this.cursorTitle && !this.isLink;
    },
  },
  beforeDestroy() {
    if (!this.isTouch()) {
      document.removeEventListener('mousemove', this.moveCursor);
      document.removeEventListener('mouseout', this.hideCursor);
    }
  },
  mounted() {
    if (!this.isTouch()) {
      document.querySelector('body').classList.add('custom-cursor');
      document.addEventListener('mousemove', this.moveCursor);
      document.addEventListener('mouseout', this.hideCursor);

      this.$root.$on('showCursorText', text => {
        this.cursorTitle = text;
      });

      this.$root.$on('hideCursorText', () => {
        this.cursorTitle = null;
      });

      this.$root.$on('hideCursor', () => {
        setTimeout(() => (this.showCursor = false), 500);
      });
    }
  },
  methods: {
    isTouch: Util.isTouch,
    hideCursor(event) {
      if (
        event.x >= document.querySelector('.main-wrapper').clientWidth ||
        event.x <= 0 ||
        event.y <= 0 ||
        event.y >= window.innerHeight
      ) {
        this.showCursor = false;
      }
    },
    moveCursor(event) {
      this.showCursor = true;
      this.isLink = false;
      if (!this.$refs.cursor) return;

      TweenLite.set(this.$refs.cursor, { x: event.x, y: event.y });

      this.$emit('moveCursor', event);

      const animation = {
        scale: 1,
        ease: Power4.easeOut,
      };

      if (
        event.target.nodeName === 'A' ||
        event.target.closest('a') ||
        event.target.nodeName === 'BUTTON' ||
        event.target.closest('button')
      ) {
        this.isLink = true;
        animation.scale = 2;

        if (this.cursorTitle) {
          animation.delay = 0.7;
        }
      }

      TweenLite.to(this.$refs.cursor, 0.5, animation);
    },
    beforeEnterText(el) {
      TweenMax.set(el, {
        opacity: 0,
        x: '-50%',
        y: '-50%',
        scaleY: 0.5,
        scaleX: 0,
      });
      TweenMax.set(el.querySelector('.span-lol'), { opacity: 0 });
    },
    enterText(el, done) {
      const tl = new TimelineMax({ onComplete: done });

      tl.to(el, 0.5, {
        opacity: 1,
        scaleY: 1,
        scaleX: 1,
        delay: 0.1,
        ease: Power4.easeOut,
        clearProps: 'all',
      });
      tl.to(el.querySelector('.span-lol'), 0.5, {
        opacity: 1,
        delay: -0.1,
        ease: Power4.easeOut,
        clearProps: 'all',
      });
    },
    leaveText(el, done) {
      const tl = new TimelineMax({ onComplete: done });

      tl.to(el.querySelector('.span-lol'), 0.2, {
        opacity: 0,
        ease: Power4.easeOut,
      });
      tl.to(el, 0.3, {
        opacity: 0,
        scaleY: 0,
        scaleX: 0,
        ease: Power4.easeOut,
        clearProps: 'all',
      });
    },
  },
};
</script>

<style lang="scss">
.custom-cursor {
  * {
    cursor: none !important;
  }
}

.cursor {
  width: em(16);
  height: em(16);
  border-radius: 50%;
  background-color: #fff;
  mix-blend-mode: difference;
  display: block;
  position: fixed;
  z-index: 9999;
  pointer-events: none;
  top: em(-8);
  left: em(-8);
  opacity: 0;

  &.show {
    opacity: 1;
  }

  &.text {
    mix-blend-mode: normal;
  }

  .text {
    position: absolute;
    min-width: em(32);
    height: em(32);
    padding: 0 em(12);
    background-color: #fff;
    color: #7f7f7f;
    white-space: nowrap;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: em(16);
    transform-origin: center;

    display: flex;
    flex-direction: column;
    justify-content: center;

    .span-lol {
      font-size: em(14);
      line-height: em(28, 28);
    }
  }
}

.cursor-enter-active,
.cursor-leave-active {
  transition: all 0.5s $curve;
}
.cursor-enter,
.cursor-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
