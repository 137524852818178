<template>
  <div v-if="loading" ref="loading" class="loading-page">
    <div class="logo-center">
      <Logo />
    </div>
  </div>
</template>

<script>
import { TweenLite, Power4 } from 'gsap';
import Logo from './Logo';

export default {
  components: {
    Logo,
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    start() {
      this.loading = true;
    },
    finish() {
      setTimeout(() => {
        if (this.$refs.loading) {
          TweenLite.to(this.$refs.loading, 1, {
            opacity: 0,
            ease: Power4.easeIn,
            onComplete: () => {
              this.loading = false;
            },
          });
        } else {
          this.loading = false;
        }
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  &-page {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: $color_bg;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
    // overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.logo-center {
  //   position: fixed;
  pointer-events: none;
  //   top: 50%;
  //   left: 50%;
  width: 100%;
  //   transform: translate(-50%, -50%);
  z-index: 15;
  mix-blend-mode: $mix_blend_mode;
  color: #fff;
  cursor: pointer;
  user-select: none;
}
</style>
