import axios from 'axios';

export default function({ store }) {
  if (store.state.projects.photos.length > 0) return;
  return axios.get('https://assets.geofflevy.com/content.json').then(res => {
    const posts = res.data.posts;

    const photos = posts.filter(o => o.categories.includes(3)).map(o => {
      return {
        id: o.id,
        index: posts.indexOf(o),
        title: decodeHtml(o.title.rendered),
        slug: `/photos/${o.slug}`,
        img: o._embedded['wp:featuredmedia'][0].source_url,
        content: o.content.rendered,
        firstImage: o.acf.first_image ? o.acf.first_image.sizes.big : null,
        thumb:
          o._embedded['wp:featuredmedia'][0].media_details.sizes.default
            .source_url,
        label: o.acf.label,
        feature: o.acf.show_on_homepage,
        type: 'photos',
      };
    });
    const films = posts.filter(o => o.categories.includes(2)).map(o => {
      return {
        id: o.id,
        index: posts.indexOf(o),
        title: decodeHtml(o.title.rendered),
        slug: `/films/${o.slug}`,
        img: o._embedded['wp:featuredmedia'][0].source_url,
        video: o.acf.video,
        video_raw: o.acf.video_loop_raw,
        vimeo: o.acf.vimeo,
        content: o.content.rendered,
        thumb:
          o._embedded['wp:featuredmedia'][0].media_details.sizes.default
            .source_url,
        label: o.acf.label,
        feature: o.acf.show_on_homepage,
        type: 'films',
      };
    });

    const logos = [
      {
        id: 'logo-sprite-sheet',
        img:
          'https://assets.geofflevy.com/wp-content/uploads/2024/01/logos-sprite.png',
      },
    ];

    store.commit('projects/pushProjects', {
      photos,
      films,
      logos,
    });

    store.commit('projects/addInfo', res.data.info[0]);
  });
}

function decodeHtml(html) {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}
