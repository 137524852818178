<script>
import { TweenLite, Power4 } from 'gsap';
import LogoPrimarySVG from './../assets/images/logo-main.svg';

export default {
  components: { LogoPrimarySVG },
  methods: {
    swapSVG() {
      const svglogo = Array.prototype.slice.call(
        document.querySelectorAll('.logo-center'),
      );
      if (svglogo) {
        TweenLite.to(svglogo, 1, {
          opacity: 0,
          ease: Power4.easeOut,
          delay: 1.5,
        });
      } else {
        console.warning('cant hide svg logo');
      }
    },
  },
};
</script>

<template>
  <div ref="svglogo" class="logo">
    <LogoPrimarySVG />
  </div>
</template>

<style lang="scss" scoped>
.logo {
  width: 100%;

  svg {
    // font-size: 10rem;
    display: block;
    width: 50%;
    margin: auto;
    height: auto;

    @media screen and (max-width: 720px) {
      width: 75%;
    }
  }
}
</style>
