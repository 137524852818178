<template>
  <main class="main-wrapper">
    <Custom-Cursor @moveCursor="moveCursor" />
    <Loading ref="loading" />
    <LoadingBar ref="loadingBar" :progress="progress" />
    <div id="scroll-pointer" />
    <nav class="main-nav">
      <transition-group name="fade">
        <nuxt-link
          v-show="isHome"
          key="home-toggle"
          :to="homeLink"
          class="main-nav__link main-nav__home"
        >
          <button class="btn-toggle-home" :class="{grid: isGrid}">
            <span class="l1" />
            <span class="l2" />
            <span class="r1" />
            <span class="r2" />
          </button>
        </nuxt-link>
        <nuxt-link v-show="!isHome" key="home" to="/" class="main-nav__link main-nav__home main-nav__home--logo">
          Geoff Sean Levy
        </nuxt-link>

        <div
          v-for="key in Object.keys(projects).filter(x=>{return x === 'photos' || x === 'films' })"
          v-show="!isGrid"
          :key="key"
          ref="navs"
          class="main-nav__link no-user-select"
          :class="key === 'photos' ? 'nav-left' : 'nav-right'"
        >
          <h2
            @click="showNavHome(key, true)"
          >
            {{ key.slice(0, -1) }}
          </h2>
          <ul
            @mouseleave="hideNavCanvas(key)"
          >
            <li
              v-for="(item, index) in featuredProjects[key]"
              :key="key + index"
              :ref="key === 'photos' ? 'navLeft' : 'navRight'"
              @mouseenter="showResourceOverride(key, index, true, true)"
            >
              <a
                :class="{active: active[key] === index}"
                @click="closeMenu($event, key, item.slug)"
              >{{ item.title }}</a>
            </li>
            <div
              class="menu-more-link"
            >
              <nuxt-link
                to="/grid" class="menu-more-link-inner"
              >
                ...
              </nuxt-link>
            </div>
          </ul>
        </div>

        <nuxt-link
          v-show="showBottomLinks"
          key="info"
          to="/info"
          class="main-nav__link main-nav__info no-user-select"
        >
          <a
            @click="closeMenu($event, 'photos','/info')"
          >
            Info
          </a>
        </nuxt-link>
        <a
          v-show="showBottomLinks"
          key="email"
          href="mailto:g@geofflevy.com"
          class="main-nav__link main-nav__email"
          target="_blank"
        >g@geofflevy.com</a>
      </transition-group>
    </nav>
    <div class="big-title">
      <div ref="title-photos" class="big-title__photos">
        <h2 class="title-h2">
          {{ titlePhoto }}
        </h2>
      </div>
      <div ref="title-films" class="big-title__films">
        <h2 class="title-h2">
          {{ titleFilm }}
        </h2>
      </div>
    </div>
    <transition name="fade" @before-enter="onBeforeEnterLogo" @before-leave="onBeforeLeaveLogo">
      <div v-show="isHome && navigating==='none'" ref="logo" class="logo-center">
        <Logo ref="logoComponent" />
      </div>
    </transition>
    <BG v-show="isHome || transition" ref="canvas" :navigating="navigating" />
    <nuxt />
  </main>
</template>

<script>
import { TweenMax, TimelineMax, Power4, Power0 } from 'gsap';

import CustomCursor from '../components/CustomCursor';
import Loading from '../components/Loading';
import LoadingBar from '../components/LoadingBar';
import BG from '../components/BG';
import Logo from '../components/Logo';

// import ScrollCtrl from '../assets/js/scroll';
// import Util from '../assets/js/util';
// import LogoPrimarySVG from './../assets/images/logo-main-tight.svg';

export default {
  components: {
    CustomCursor,
    Loading,
    LoadingBar,
    BG,
    Logo,
    // LogoPrimarySVG,
  },
  data: () => ({
    progress: 0,
    navigating: 'none',
    transition: false,
    pauseLeave: false,
    active: {
      photos: 0,
      films: 0,
    },
    timelines: {
      photos: null,
      films: null,
    },
    activeMobileNav: null,
    scroll: 0,
  }),
  computed: {
    isTouch() {
      return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
    homeLink() {
      return this.$route.name !== 'grid' ? '/grid' : '/';
    },
    isGrid() {
      return this.$route.name === 'grid';
    },
    isHome() {
      return (
        this.$route.name === 'grid' ||
        this.$route.name === 'index' ||
        this.$route.path === '/'
      );
    },
    isProjectPage() {
      return (
        this.$route.name === 'photos-id' || this.$route.name === 'films-id'
      );
    },
    showBottomLinks() {
      return !this.isGrid && this.$route.name !== 'info';
    },
    projects() {
      return this.$store.state.projects;
    },
    logos() {
      return this.$store.state.projects.logos;
    },
    featuredProjects() {
      // return this.$store.state.projects;
      // FILTER to just ones that should show on homescreen
      const data = {
        films: this.$store.state.projects.films.filter(x => {
          return x.feature;
        }),
        photos: this.$store.state.projects.photos.filter(x => {
          return x.feature;
        }),
      };
      return data;
    },
    titlePhoto() {
      return (
        this.projects.photos.length &&
        this.projects.photos[this.active.photos].title
      );
    },
    titleFilm() {
      return (
        this.projects.films.length &&
        this.projects.films[this.active.films].title
      );
    },
    isMobile() {
      return this.$store.state.mobile.isMobile;
    },
  },
  watch: {
    $route(to, from) {
      this.$root.$emit('hideCursorText');

      this.hideNav();

      this.transition = true;

      setTimeout(() => {
        this.transition = false;
      }, 1000);

      //   if (window.mainScroll) {
      //     window.mainScroll.pause = true;
      //   }

      //   if (!this.isMobile) {
      //     const menuItems = this.$el.querySelectorAll(
      //       '.nav-left li, .nav-right li',
      //     );
      //     TweenMax.set(menuItems, {
      //       display: this.isHome && !this.isGrid ? 'block' : 'none',
      //     });
      //   }

      //   if (
      //     to.name !== 'films-id' &&
      //     to.name !== 'photos-id' &&
      //     to.name !== 'grid' &&
      //     to.name !== 'index'
      //   ) {
      //   setTimeout(() => {
      //   const sections = Array.from(
      //     document.querySelectorAll('.scroll-section'),
      //   ).map($el => {
      //     return { $el: $el };
      //   });
      //   if (window.mainScroll) {
      //     window.mainScroll.updateContent(
      //       document.querySelector('.scroll-page'),
      //       sections,
      //     );
      //   }

      //     this.transition = false;
      //   }, 1000);
      //   } else if (
      //     (from.name === 'index' &&
      //       (to.name === 'photos-id' || to.name === 'films-id')) ||
      //     (from.name === 'photos-id' && to.name === 'photos-id') ||
      //     (from.name === 'films-id' && to.name === 'films-id')
      //   ) {
      // this.progress = 0;
      // const progressInterval = setInterval(() => {
      //   this.progress += 15;
      // }, 500);
      // this.$refs.loadingBar.start();
      // this.transition = true;
      // setTimeout(() => {
      //   clearInterval(progressInterval);
      // }, 3000);
      //   } else {
      //     this.transition = true;

      // setTimeout(() => {
      //   const sections = Array.from(
      //     document.querySelectorAll('.scroll-section'),
      //   ).map($el => {
      //     return { $el: $el };
      //   });

      //   if (window.mainScroll) {
      //     window.mainScroll.updateContent(
      //       document.querySelector('.scroll-page'),
      //       sections,
      //     );
      //     window.mainScroll.pause = false;
      //   }
      //   this.transition = false;
      // }, 1000);
      //   }
    },
  },
  beforeDestroy() {
    this.$router.push(this.$route.path);
  },
  async mounted() {
    this.onResize();
    window.onresize = this.onResize;

    if (this.isTouch) {
      document.querySelector('body').classList.add('is-touch');
    }

    // lol mobile + zooming is bad
    setInterval(this.onResize, 1000);

    this.$refs.loading.start();
    this.$refs.loadingBar.start();
    /*
    if (!window.mainScroll) {
      const sections = Array.from(
        document.querySelectorAll('.scroll-section'),
      ).map($el => {
        return { $el: $el };
      });
      window.mainScroll = new ScrollCtrl(
        document.querySelector('.scroll-page'),
        sections,
        [],
      );
      //   window.mainScroll = window.mainScroll;
    }
    */
    const promisesFilms = this.projects.films
      .filter(x => {
        return x.feature;
      })
      .map(film => this.loadVideo(film));
    const promisesPhotos = this.projects.photos
      .filter(x => {
        return x.feature;
      })
      .map(photo => this.loadPhoto(photo));

    const promisesLogos = [this.loadLogoSprite(this.logos[0])];

    this.$root.$on('projectReady', () => {
      if (this.$root.homeReady) {
        this.initProject();
      }
    });

    await Promise.all([
      ...promisesPhotos,
      ...promisesFilms,
      ...promisesLogos,
    ]).catch(err => console.log(err));
    // console.log('photos & films loaded');

    this.$refs.canvas.init();

    this.$root.homeReady = true;
    this.$root.$emit('homeReady');

    this.$refs.logoComponent.swapSVG();

    if (!this.isProjectPage) {
      this.progress = 100;
      if (this.$refs.loading) {
        this.$refs.loading.finish();
      }
      if (this.isHome) {
        if (this.$refs.loadingBar) {
          this.$refs.loadingBar.finish();
        }
        this.$refs.canvas.showSprite();
      }
    } else if (this.$root.projectReady) {
      this.$refs.canvas.hideSprite();
      this.initProject();
    }

    this.$root.$on('showMenuPhoto', () => {
      //   console.log('show menu photo');
      this.showNav('photos');
    });

    this.$root.$on('showMenuFilm', () => {
      // console.log('show menu film');
      this.showNav('films');
    });
    this.$root.$on('hideMenu', () => {
      this.hideNav();
    });

    this.$root.$on('showResource', i => {
      //   console.log('show resource', i);
      this.showResource('films', i, true, false);
      // this.showNav('films');
    });

    this.$root.$on('glitchLogo', () => {
      //   console.log('show resource', i);
      this.glitchLogo();
      // this.showNav('films');
    });

    setTimeout(() => {
      this.glitchLogo();
    }, 1750);

    // document
    //   .querySelector('.main-wrapper')
    //       .addEventListener('scroll', this.scrollEvents);

    // const options = {
    //   root: document.querySelector('#scrollArea'),
    //   rootMargin: '0px',
    //   threshold: 1.0,
    // };

    // this.observer = new IntersectionObserver(this.scrollEvents, options);
  },
  methods: {
    initProject() {
      //   setTimeout(() => {
      this.progress = 100;
      if (this.$refs.loading) {
        this.$refs.loading.finish();
      }
      if (this.$refs.loadingBar) {
        this.$refs.loadingBar.finish();
      }
      if (this.$route.query.next !== '1') {
        const sections = Array.from(
          document.querySelectorAll('.scroll-section'),
        ).map($el => {
          return { $el: $el };
        });
        if (window.mainScroll) {
          window.mainScroll.updateContent(
            document.querySelector('.scroll-page'),
            sections,
          );
        }
      }
      this.transition = false;
      //   }, String(this.$route.query.next) === '1' ? 500 : 500);
    },
    onResize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);

      const w = document.querySelector('.main-wrapper').clientWidth;

      document.documentElement.style.setProperty(
        '--scrollwidth',
        `${window.innerWidth - w}px`,
      );

      document.querySelector('.main-nav').style.width = w + 'px';
      document.querySelector('.canvas-container').style.width = w + 'px';
      document.querySelector('.logo-center').style.width = w + 'px';

      this.checkMobile();
    },
    glitchLogo() {
      if (this.glitchTl) {
        this.glitchTl.pause();
        this.glitchTl.kill();
        this.glitchTl = null;
      }

      this.glitchTl = new TimelineMax({
        paused: true,
        onComplete: () => {
          this.glitchTl.kill();
          this.glitchTl = null;
        },
        onUpdate: () => {
          this.$refs.canvas.mouseMoved = true;
        },
      });

      const dur = (7 / 9) * (7 / 14);

      this.glitchTl.add(
        TweenMax.to(this.$refs.canvas.mouseCache, dur, {
          x: 1200,
          y: 1200,
          ease: Power0.easeNone,
        }),
        0.0,
      );

      this.glitchTl.add(
        TweenMax.to(this.$refs.canvas.mouseCache, dur, {
          x: 0,
          y: 0,
          ease: Power0.easeNone,
        }),
        dur, // 7/9 is exactly one cycle of all the logos
      );

      this.glitchTl.play();
    },
    checkMobile() {
      //   if (document.querySelector('.main-wrapper').clientWidth < 780) {
      //     this.$store.commit('mobile/change', true);
      //   } else {
      this.$store.commit('mobile/change', false);
      //   }
    },
    moveCursor(event) {
      //   console.log('after', position);
      this.$refs.canvas.handleMouse(event);
    },
    incrementLoading() {
      const total = this.projects.films.length + this.projects.photos.length;
      const increment = 100 / total;

      if (this.progress + increment >= 100) {
        this.progress = 100;
      } else {
        this.progress += increment;
      }
    },
    loadVideo(obj) {
      return new Promise((resolve, reject) => {
        let imageReady = false;
        let videoReady = false;
        const img = new Image();
        const video_parent = document.createElement('div');
        video_parent.innerHTML =
          '<video muted playsinline type="video/mp4" loop crossOrigin="anonymous" preload="metadata"></video>';
        const video = video_parent.querySelector('video');

        img.onload = () => {
          imageReady = true;
          if (videoReady) {
            this.incrementLoading();
            resolve({ img, video });
          }
        };

        img.onerror = e => {
          resolve({ img: null, video });
        };

        img.crossOrigin = '';
        img.src = obj.img;

        // DO NOT download video metadata from vimeo link
        // video.src = obj.video;
        // INSTEAD only get it if its hosted on S3/Cloudfront
        video.src = obj.video_raw ? obj.video_raw : '';
        video.preload = 'metadata';

        this.$store.commit('projects/addMedia', {
          type: 'films',
          obj,
          media: video,
        });
        this.$store.commit('projects/addCover', {
          type: 'films',
          obj,
          cover: img,
        });
        video.onloadedmetadata = () => {
          handleVR();
        };
        video.onerror = () => {
          document.querySelector('h2').style.color = 'red';
          handleVR();
        };
        const handleVR = () => {
          video.pause();
          videoReady = true;
          if (
            // (Util.testBrowser('safari') || Util.testBrowser('safari mobile')) &&
            imageReady
          ) {
            this.incrementLoading();
            resolve({ img, video });
          } /* else if (
            !Util.testBrowser('safari') ||
            Util.testBrowser('safari mobile')
          ) {
            this.incrementLoading();
            resolve({ img, video });
          } */
        };
      });
    },
    loadPhoto(obj) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          this.incrementLoading();
          resolve(img);
        };
        img.onerror = e => {
          resolve(img);
        };
        img.crossOrigin = '';
        img.src = obj.img;
        this.$store.commit('projects/addMedia', {
          type: 'photos',
          obj,
          media: img,
        });
      });
    },
    loadLogoSprite(obj) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
          resolve(img);
        };
        img.onerror = e => {
          resolve(img);
        };
        img.dataset.name = 'logo_sprite';
        img.crossOrigin = '';
        img.src = obj.img;
        this.$store.commit('projects/addMedia', {
          type: 'logos',
          obj,
          media: img,
        });
      });
    },
    // scrollEvents(entries, observer) {
    //   //   console.log(event);
    //   //   this.scroll = event.target.scrollTop;

    //   //   console.log(this.scroll);
    //   console.log(entries);
    // },
    navToInfo() {
      this.hideNav();
    },
    showNavHome(section, fullscreenCanvas) {
      if (!this.isTouch && this.isHome) {
        return;
      }
      if (this.isHome && !this.isGrid) {
        // toggle mobile
        if (this.navigating === section) {
          this.hideNavCanvas();
          this.hideNav();
          return false;
        } else if (this.navigating !== 'none') {
          this.hideNav();
          setTimeout(() => {
            this.showNav(section, fullscreenCanvas);
          }, 250);
        } else {
          this.showNav(section, fullscreenCanvas);
        }
      } else if (!this.isHome) {
        this.navigating = 'none';
        this.$router.push('/');
        // setTimeout(() => {
        this.showNav(section, fullscreenCanvas);
        // }, 500);
      }
    },
    showNav(section, fullscreenCanvas) {
      if (this.navigating !== 'none') {
        return false;
      }

      this.navigating = section;

      const nav =
        section === 'photos' ? this.$refs.navs[0] : this.$refs.navs[1];

      if (nav) {
        // console.log('open menmu', section);
        const ul = nav.querySelector('ul');

        if (this.timelines[section] !== null) {
          this.timelines[section].pause();
          this.timelines[section].kill();
          this.timelines[section] = null;
        }

        this.timelines[section] = new TimelineMax({
          paused: true,
          onStart: () => {
            ul.classList.add('open');
          },
          onComplete: () => {
            TweenMax.set(menuItems, { opacity: 1, y: 0 });
            this.timelines[section].pause();
            this.timelines[section].kill();
            this.timelines[section] = null;
          },
        });

        const menuItems = Array.from(nav.querySelectorAll('li'));
        menuItems.push(nav.querySelector('.menu-more-link'));

        TweenMax.set(menuItems, { opacity: 0, y: -8 });

        this.timelines[section].staggerTo(
          menuItems,
          0.18,
          {
            opacity: 1,
            y: 0,
            // display: 'block',
            ease: Power4.easeOut,
            // clearProps: 'all',
          },
          0.025,
          0.0,
        );

        // If you remove this, the onStart callback never fires, bc old GSAP + Staggers was a bit wack
        this.timelines[section].set({}, {}, 0.001);

        this.timelines[section].play();

        if (fullscreenCanvas) {
          this.$refs.canvas.showNav(section);
        }

        //   this.$refs.canvas.showNav(section);
        // this.showResource(section, this.active[section], true);
      }
    },
    hideNavCanvas(section) {
      this.$refs.canvas.nav_hovering = false;
      this.$refs.canvas.hideNav(section);
    },
    hideNav(section, go) {
      if (this.navigating === 'none') return false;
      const section_cache = '' + this.navigating;
      this.navigating = 'none';

      const nav_1 = this.$refs.navs[0];
      const nav_2 = this.$refs.navs[1];

      const ul_1 = nav_1.querySelector('ul');
      const ul_2 = nav_2.querySelector('ul');

      const lis_1 = Array.from(nav_1.querySelectorAll('li'));
      lis_1.push(nav_1.querySelector('.menu-more-link'));
      lis_1.reverse().filter(x => {
        return x != null;
      });
      const lis_2 = Array.from(nav_2.querySelectorAll('li'));
      lis_2.push(nav_2.querySelector('.menu-more-link'));
      lis_2.reverse().filter(x => {
        return x != null;
      });

      if (this.timelines[section_cache] !== null) {
        this.timelines[section_cache].pause();
        this.timelines[section_cache].kill();
        this.timelines[section_cache] = null;
      }

      this.timelines[section_cache] = new TimelineMax({
        paused: true,
        onStart: () => {
          ul_1.classList.remove('open');
          ul_2.classList.remove('open');
        },
        onComplete: () => {
          this.timelines[section_cache].pause();
          this.timelines[section_cache].kill();
          this.timelines[section_cache] = null;
        },
      });

      this.timelines[section_cache].staggerTo(
        lis_1,
        0.3,
        {
          opacity: 0,
          y: -5,
          //   display: this.isHome && !this.isGrid ? 'block' : 'none',
          ease: Power4.easeOut,
          //   overwrite: 'all',
        },
        0.01,
        0.0,
      );

      this.timelines[section_cache].staggerTo(
        lis_2,
        0.3,
        {
          opacity: 0,
          y: -5,
          //   display: this.isHome && !this.isGrid ? 'block' : 'none',
          ease: Power4.easeOut,
          //   overwrite: 'all',
        },
        0.01,
        0.0,
      );

      /*
      if (!this.transition) {
        if (this.isProjectPage) {
          tl.to(
            this.$refs[`title-${section}`],
            0.01,
            // { opacity: 1 },
            {
              opacity: 0,
              //   delay: -1,
              ease: Power4.easeOut,
              overwrite: 'all',
            },
          );
        } else {
          tl.to(
            this.$refs[`title-${section}`],
            0.3,
            // { opacity: 1 },
            {
              opacity: 0,
              //   delay: 0.0,
              ease: Power4.easeOut,
              overwrite: 'all',
            },
          );
        }
      } else {
        tl.to(
          this.$refs[`title-${section}`],
          0.3,
          //   { opacity: 1 },
          {
            opacity: 0,
            // delay: 1,
            ease: Power4.easeOut,
            overwrite: 'all',
          },
        );
      }
      */

      this.timelines[section_cache].play();

      this.$refs.canvas.hideNav(section, go);

      if (go === 'info') {
        this.$refs.canvas.setPhotosConfig();
      }
    },
    showResourceOverride(key, index, showTitle, fullscreenCanvas) {
      this.$refs.canvas.nav_hovering = true;
      this.showResource(key, index, showTitle, fullscreenCanvas);
    },
    showResource(key, index, showTitle, fullscreenCanvas) {
      //   if (this.isMobile) return false;
      this.active[key] = index;
      if (fullscreenCanvas) {
        this.$refs.canvas.showNav(key);
      }
      this.$refs.canvas.showResource(key, index, showTitle);
    },
    closeMenu($event, key, slug) {
      if ($event) {
        $event.preventDefault();
      }

      //   if (this.isMobile) {
      //     this.$refs.canvas.hideNav(key, true);
      //     this.closeMobileNav();
      //   } else {
      const items = Array.from(
        document.querySelectorAll(
          '.project-page .big-title, .next-project .big-title__photos, .next-project .big-title__films',
        ),
      );
      items.forEach(item => (item.style.opacity = 0));
      //   this.hideNav(key, true);
      //   }

      this.transition = true;
      this.$router.push({ path: slug });
    },
    showMobileNav(key) {
      //   if (!this.$refs.mobileOverlay) return;
      //   if (this.activeMobileNav === key) return this.closeMobileNav();
      //   const nav = key === 'photos' ? this.$refs.navs[0] : this.$refs.navs[1];
      //   const tl = new TimelineMax();
      //   tl.fromTo(
      //     this.$refs.mobileOverlay,
      //     0.7,
      //     { opacity: 0, display: 'none' },
      //     {
      //       opacity: 1,
      //       display: 'flex',
      //       ease: Power4.easeOut,
      //     },
      //   );
      //   tl.to(nav.querySelector('ul'), 0.5, {
      //     opacity: 1,
      //     display: 'block',
      //     delay: -0.5,
      //   });
      //   this.activeMobileNav = key;
    },
    closeMobileNav() {
      //   const nav =
      //     this.activeMobileNav === 'photos'
      //       ? this.$refs.navs[0]
      //       : this.$refs.navs[1];
      //   const tl = new TimelineMax();
      //   tl.to(nav.querySelector('ul'), 0.1, {
      //     opacity: 0,
      //     display: 'none',
      //     ease: Power4.easeOut,
      //     overwrite: 'all',
      //   });
      //   tl.to(this.$refs.mobileOverlay, 1, {
      //     opacity: 0,
      //     display: 'none',
      //     ease: Power4.easeOut,
      //     overwrite: 'all',
      //   });
      //   this.activeMobileNav = null;
    },
    onBeforeEnterLogo() {
      //   console.log(this.$refs.logoComponent);
      //   console.log(this.$refs.canvas);
      if (this.isHome) {
        this.$refs.canvas.showSpriteDefer();
      }
      //   console.log(logoShape);
      //   this.components.BG.methods.showSprite();
    },
    onBeforeLeaveLogo() {
      //   this.components.BG.methods.hideSprite();
      this.$refs.canvas.hideSprite();
    },
  },
};
</script>

<style lang="scss" scoped>
.main-wrapper {
  position: relative;
  //   top: 0;
  //   left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  overflow-y: scroll;
}
#scroll-pointer {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 9998;
  position: absolute;
  transform: translateZ(0);
}
.main-nav {
  position: fixed;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 20;
  pointer-events: none;

  &__overlay {
    background-color: #000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: em(20);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    display: none;

    button {
      font-size: em(18);
    }
  }

  &__link {
    font-size: em(20);
    display: flex;
    mix-blend-mode: $mix_blend_mode;
    color: #fff;
    position: absolute;
    z-index: 15;

    @media screen and (max-width: $mobile_breakpoint) {
      font-size: em(18);
    }
  }

  &__home {
    // transition-delay: 1s;
    top: 1rem;
    line-height: 1.3em;
    left: 50%;
    transform: translateX(-50%);
    font-family: $font_title;
    pointer-events: initial;
    z-index: 22;

    // &--logo {
    //   svg {
    // height: auto;
    // width: 16rem;
    // margin-left: auto;
    // margin-right: auto;
    //   font-size: 1.6em;
    //   line-height: 1.15em;
    //   }
    //   width: 100%;
    //   text-align: center;
    //   margin-left: auto;
    //   margin-right: auto;
    //   padding: 3px;
    //   display: flex;
    //   justify-content: center;
    //   flex-direction: column;
    // }
  }

  &__info {
    bottom: 1rem;
    left: rem(20);
    pointer-events: initial;
  }

  &__email {
    bottom: 1rem;
    right: rem(20);
    pointer-events: initial;
    // padding: 1rem;
    // bottom: 0;
    // right: calc( rem(20) - 1rem );
  }

  .nav {
    &-left,
    &-right {
      display: inline-block;
      position: relative;
      top: 1rem;
      z-index: 21;
      width: calc(50% - 1rem);

      .helper-space {
        position: absolute;
        width: 100%;
        font-size: 1em;
        height: 2em;
        top: -2em;
        left: 0;
      }

      h2 {
        width: 5rem;
        // margin-left: auto;
        font-size: 1em;
        line-height: em(24, 20);
        text-transform: capitalize;
        cursor: pointer;
        pointer-events: initial;
      }

      ul {
        position: absolute;
        z-index: 1;
        padding-top: 1rem;

        pointer-events: none;

        &.open {
          pointer-events: initial;
        }

        // @media screen and (max-width: $mobile_breakpoint) {
        //   display: none;
        //   opacity: 0;
        // }

        li {
          //   pointer-events: initial;
          line-height: em(24, 20);
          padding-bottom: em(5, 20);
          white-space: nowrap;
          opacity: 0;

          a {
            cursor: pointer;
            position: relative;

            opacity: 0.5;

            &.active,
            &:hover {
              opacity: 1;
              &::after {
                content: '';
                position: absolute;
                height: 2px;
                width: 100%;
                left: 0;
                right: 0;
                bottom: 0em;
                z-index: -1;

                //   @extend .greenish;
                background-color: white;
              }
            }
          }
        }

        .menu-more-link {
          line-height: em(24, 20);
          padding-bottom: em(5, 20);
          white-space: nowrap;
          opacity: 0;

          .menu-more-link-inner {
            display: inline-block;
            cursor: pointer;
            position: relative;

            opacity: 0.5;

            &:hover {
              opacity: 1;
              &::after {
                content: '';
                position: absolute;
                height: 2px;
                width: 100%;
                left: 0;
                right: 0;
                bottom: 0em;
                z-index: -1;

                //   @extend .greenish;
                background-color: white;
              }
            }
          }

          //   @media screen and (max-width: $mobile_breakpoint) {
          //     opacity: 1;
          //   }

          // &:not(.active) {
          // }
        }
      }
    }

    &-left {
      margin-left: 1rem;
      text-align: left;

      h2 {
        // margin-right: 1em;
        margin-right: auto;
      }

      ul {
        left: 0;
      }
    }

    &-right {
      margin-right: 1rem;
      text-align: right;

      h2 {
        order: 2;
        margin-left: auto;
      }

      ul {
        right: 0;
      }
    }
  }
}

.big-title {
  display: none;

  &__photos,
  &__films {
    z-index: 15;
    opacity: 0;
  }
}

.logo-center {
  position: fixed;
  pointer-events: none;
  //   top: 50%;
  //   left: 50%;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  //   transform: translate(-50%, -50%);
  z-index: 15;
  mix-blend-mode: $mix_blend_mode;
  color: #fff;
  cursor: pointer;
  user-select: none;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;

  //   svg {
  // width: 50%;
  // margin: auto;
  //   }
}

.btn-toggle-home {
  position: relative;
  font-size: 1em;
  width: em(20);
  height: em(20);
  transition: transform 0.125s $curve;

  span {
    width: em(8);
    height: em(8);
    position: absolute;
    border: solid 1px white;
    box-sizing: border-box;
    transition: 0.25s $curve 0.125s;

    &.l1 {
      left: 0px;
      top: 0px;
    }

    &.l2 {
      left: 0px;
      bottom: 0px;
    }

    &.r1 {
      right: 0px;
      top: 0px;
    }

    &.r2 {
      right: 0px;
      bottom: 0px;
    }
  }

  &.grid {
    transform: rotate(-90deg);
    transition: 0.25s $curve;

    span {
      &.l1 {
        width: em(20);
      }

      &.l2 {
        width: em(20);
      }

      &.r1 {
        width: 0px;
        opacity: 0;
      }

      &.r2 {
        width: 0px;
        opacity: 0;
      }
    }
  }
}
</style>
