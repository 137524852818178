<template>
  <div class="canvas-container" :class="{'above-all': navOpen}">
    <transition @beforeEnter="beforeEnter" @enter="enter" @leave="leave">
      <canvas v-show="showCanvas" ref="canvas" class="canvas" />
    </transition>
  </div>
</template>

<script>
import { TweenMax, Power4 } from 'gsap';
import vertex from '../assets/shaders/vertex.glsl';
import fragment from '../assets/shaders/fragment.glsl';
import logo_vertex from '../assets/shaders/logo-vert.glsl';
import logo_fragment from '../assets/shaders/logo-frag.glsl';

import Util from '../assets/js/util';
import Lag from '../assets/js/lag';
import webgl from '../mixins/webgl';
// import Logo from './Logo.vue';

export default {
  mixins: [webgl],
  props: {
    navigating: { type: String, default: 'none' },
  },
  data: () => ({
    navOpen: false,
    headerVideo: null,
    headerImage: null,
    nextProjectOffset: 60,
    fromNext: false,
    aboveAll: false,
    nav_hovering: false,
  }),
  computed: {
    isGrid() {
      return this.$route.name === 'grid';
    },
    isHome() {
      return this.$route.path === '/';
    },
    showCanvas() {
      return this.$route.name === 'index';
      //   return
    },
    projects() {
      // FILTER to just ones that should show on homescreen
      const data = {
        films: this.$store.state.projects.films.filter(x => {
          return x.feature;
        }),
        photos: this.$store.state.projects.photos.filter(x => {
          return x.feature;
        }),
      };
      return data;
    },
    logoAssets() {
      return this.$store.state.projects.logos;
    },
    logoShape() {
      let s = null;
      if (this.shapes) {
        this.shapes.forEach(shape => {
          if (shape.name === 'logo_0') {
            s = shape;
          }
        });
      }
      return s;
    },
    currentType() {
      if (this.$route.name === 'photos-id') return 'photos';
      if (this.$route.name === 'films-id') return 'films';
      else return '';
    },
    currentProject() {
      const slug = this.$route.path;
      if (this.currentType === 'photos' || this.currentType === 'films')
        return this.projects[this.currentType].find(o => o.slug === slug);
      return {};
    },
    nextProject() {
      if (this.currentType !== 'photos' && this.currentType !== 'films')
        return false;

      const nextIndex =
        this.projects[this.currentType].indexOf(this.currentProject) + 1;

      if (nextIndex < this.projects[this.currentType].length) {
        return this.projects[this.currentType][nextIndex];
      } else {
        return false;
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.name === 'index') {
        this.beforeEnter();
        this.setHomeConfig();
        this.enter();
      } else {
        this.leave();
      }
      //     }, 800);
      //   } else if (to.name === 'index') {
      //     this.setHomeConfig();
      //   } else if (
      //     from.name === 'index' &&
      //     (to.name === 'photos-id' || to.name === 'films-id')
      //   ) {
      //     this.leave(() => {
      //       this.beforeEnter();
      //       this.enter();
      //       //   if (to.name === 'films-id') this.animationVideoTransition();
      //     });
      //     // setTimeout(() => {
      //     // this.setPhotosConfig();
      //     // }, 1500);
      //   } else if (
      //     (to.name === 'photos-id' || to.name === 'films-id') &&
      //     (from.name === 'photos-id' || from.name === 'films-id') &&
      //     this.$route.query.next === '1'
      //   ) {
      //     this.fromNext = true;
      //   } else if (to.name === 'photos-id' || to.name === 'films-id') {
      //     if (to.name === 'films-id') this.animationVideoTransition();
      //     this.setPhotosConfig();
      //   }
    },
  },
  mounted() {
    this.ready = false;
    this.lag = new Lag(
      {
        x: 0,
        y: 0,
      },
      {
        buffer_length: 12,
        lag_format: 'kalman',
      },
    );
    this.$root.$on('allReady', () => {
      const next = this.fromNext;
      this.fromNext = false;
      if (!next && this.currentType === 'films') {
        // let video = this.shapes.find(s => s.name === 'video_transition');
      }
      if (next) this.animationNext(this.$route.name === 'films-id');
      else {
        if (window.mainScroll) {
          window.mainScroll.maxVirtual = 0;
          window.mainScroll.onlyVirtual = false;
        }
        this.setPhotosConfig();
        // this.loadProjectImages();
      }
    });
  },
  methods: {
    beforeEnter(el) {
      TweenMax.set(this.$refs.canvas, {
        opacity: 0,
        // scale: 0.95,
      });
      this.setPositions();
    },
    enter(el, done) {
      TweenMax.to(this.$refs.canvas, 1, {
        opacity: 1,
        delay: 0.1,
        // scale: 1,
        ease: Power4.easeOut,
        onComplete: done,
      });
    },
    leave(el, done) {
      TweenMax.to(this.$refs.canvas, 0.25, {
        opacity: 0,
        // scale: 0.95,
        ease: Power4.easeOut,
        onComplete: done,
      });
    },
    getLogoShape() {
      let s = null;
      console.log(this.shapes);
      if (this.shapes) {
        this.shapes.forEach(shape => {
          if (shape.name === 'logo_0') {
            s = shape;
          }
        });
      }
      return s;
    },
    showSpriteDefer() {
      clearTimeout(this.showSpriteDeferTimer);
      if (this.logoShape) {
        // this.showSpriteDeferTimer = setTimeout(() => {
        this.logoShape.opacity.t = 1.0;
        // }, 400);
      }
    },
    showSprite() {
      clearTimeout(this.showSpriteDeferTimer);
      if (this.logoShape) {
        this.logoShape.opacity.t = 1.0;
      }
    },
    hideSprite() {
      clearTimeout(this.showSpriteDeferTimer);
      this.logoShape.opacity.t = 0.0;
      //   console.log('hide');
    },
    setHomeConfig() {
      this.clearProjects();
      this.headerImage.render = true;
      this.headerVideo.render = true;

      this.headerImage.opacity.t = 1;
      this.headerImage.opacity.c = 1;

      this.headerVideo.opacity.t = 1;
      this.headerVideo.opacity.c = 1;

      this.headerImage.mask.t[0] = 0.5;
      this.headerImage.mask.c[0] = 0.5;

      this.headerVideo.mask.t[0] = -0.5;
      this.headerVideo.mask.c[0] = -0.5;
    },
    setPhotosConfig(next) {
      this.clearProjects(next);
      this.headerImage.opacity.t = 0;
      this.headerVideo.opacity.t = 0;
      //   setTimeout(() => {
      // this.loadProjectImages(next);
      //   }, 1000);
    },
    init() {
      this.vertex = vertex;
      this.fragment = fragment;
      this.logo_vertex = logo_vertex;
      this.logo_fragment = logo_fragment;

      document
        .querySelector('.logo-center')
        .addEventListener('mousedown', () => {
          //   this.noise_2.t = 0.2;
        });
      document.addEventListener('mouseup', () => {
        // this.noise_2.t = 0;
      });
      //   if (this.isMobile) {
      //     const photo = this.projects.photos[0].media;
      //     const film =
      //     //   Util.testBrowser('safari') ||
      //     //   Util.testBrowser('safari mobile') ||
      //       this.projects.films[0].media.error
      //         ? this.projects.films[0].cover
      //         : this.projects.films[0].media;

      //     photo.dataset.name = 'photo_0';
      //     film.dataset.name = 'film_0';
      //     this.medias.push(photo);
      //     this.medias.push(film);
      //   } else {
      this.medias = this.projects.photos
        .map((p, i) => {
          p.media.dataset.name = 'photo_' + i;
          return p.media;
        })
        .concat(
          this.projects.films.map((f, i) => {
            if (
              // Util.testBrowser('safari') ||
              // Util.testBrowser('safari mobile') ||
              f.media.error
            ) {
              f.cover.dataset.name = 'film_' + i;
              return f.cover;
            }
            f.media.dataset.name = 'film_' + i;
            return f.media;
          }),
        )
        .concat(
          this.logoAssets.map((f, i) => {
            f.media.dataset.name = 'logo_' + i;
            return f.media;
          }),
        );
      //   }

      this.setOptions();

      //   console.log('initedddd ');
      // this.loadProjectImages();

      this.setHomeConfig();
      this.enter();
    },
    clearProjects(next) {
      this.shapes = this.shapes.filter(media => !media.project);
      /* this.medias = this.medias.filter((media, i) => this.shapes.some(s => s.texture === i));
      this.textures = this.textures.filter((texture, i) => this.shapes.some(s => s.texture === i)); */
      if (!next) {
        /* setTimeout(() => {
          this.clearCanvas = true;
        }, 500); */
      }
    },
    animationNext(video) {
      /*
      window.mainScroll.pause = true;
      //   console.log('animationNext');
      const projects = this.shapes.filter(
        s => s.render && s.project && s.name !== 'project_next',
      );
      const firstShape = this.shapes.find(s => s.name === 'project_0') || {
        x: 0,
        y: 0,
      };
      const nextShape = this.shapes.find(s => s.name === 'project_next');
      const scroll = nextShape.scroll_inertia
        ? this.scroll.t - nextShape.scroll_inertia * this.scroll.t
        : this.scroll.t;
      let configNext = {
        x: { t: firstShape.x, c: nextShape.x },
        y: {
          t: nextShape.y - (nextShape.y - scroll) + firstShape.y + 60,
          c: nextShape.y,
        },
      };
      projects.forEach(s => {
        // const y = { t: s.y - s.h * 0.2, c: s.y };
        s.opacity.t = 0;
      });
      if (!video) {
        setTimeout(() => {
          nextShape.y_target = configNext.y.t;
          nextShape.x_target = configNext.x.t;
          projects.forEach(s => {
            s.render = false;
          });
          console.log('animation start');
          setTimeout(() => {
            window.mainScroll.maxVirtual = 0;
            window.mainScroll.onlyVirtual = false;
            console.log('reset webgl');
            this.setPhotosConfig(true);
            this.loadProjectImages(true);
            // this.loadLogoSprite();
          }, 2000);
        }, 1000);
      } else {
        nextShape.mask.t[1] = -1;
        configNext = {
          y: {
            t:
              nextShape.y -
              (nextShape.y - scroll) +
              this.$el.clientHeight * 0.5 -
              nextShape.h * 0.5,
            c: nextShape.y,
          },
        };

        // nextShape.y_target = configNext.y.t;
        // TweenMax.fromTo(nextShape, 2, {y: configNext.y.c}, {y: configNext.y.t, ease: Power4.easeOut});
        setTimeout(() => {
          nextShape.opacityMultiplier = 0.03;
          nextShape.opacity.t = 0;
          setTimeout(() => {
            window.mainScroll.maxVirtual = 0;
            window.mainScroll.onlyVirtual = false;
            this.setPhotosConfig(true);
            this.loadProjectImages(true);
          }, 3500);
        }, 1000);
      } */
    },
    animationVideoTransition() {
      /*
      if (this.currentProject.cover) {
        setTimeout(() => {
          //   console.log('animation video', this.currentProject.slug);
          const video = this.shapes.find(s => s.name === 'video_transition');
          const media = this.currentProject.cover;
          const index = this.medias.push(media) - 1;
          this.addTexture(media);
          video.ratio = media.naturalWidth / media.naturalHeight;
          video.texture = index;

          // video.color = {c: [1, 0, 0], t: [1, 0, 0]};
          video.mask.t[1] = -0.1;
          video.mask.c[1] = -0.1;
          video.opacity.t = 1;
          video.opacity.c = 1;
          video.render = true;
          video.mask.t[1] = -1;
          video.y_target = 50;
          setTimeout(() => {
            // console.log('hide --video');
            video.opacity.t = 0;
          }, 2000);
        }, 500);
      } */
    },
    loadProjectImages(next) {
      /*
      //   console.log('webgl-ready---');
      if (!next) {
        if (window.mainScroll) {
          window.mainScroll.target = 0;
          window.mainScroll.data.t = 0;
          window.mainScroll.data.c = 0;
          window.mainScroll.resize();
        }
      }
      if (window.mainScroll) {
        this.scroll = window.mainScroll.data;
        window.mainScroll.addParalax(this.handleScroll);
      } else {
        this.scroll = { c: 0, t: 0 };
      }

      const points = {
        x: 8,
        y: 16,
      };
      Array.from(document.querySelectorAll('.project-page img')).forEach(
        (media, i) => {
          const m = media;
          this.medias.push(m);
          this.addTexture(m);
          const figure = media.closest('figure');
          const ratio = m.naturalWidth / m.naturalHeight;
          const nextProjectVideo = figure.closest('.next-project--video');
          figure.setAttribute('data-shape', this.shapes.length);
          const isNext = figure.classList.contains('next-project__image');
          const zindex = getComputedStyle(figure).zIndex === 'auto' ? 1 : 3;
          //   const inertia = isNext ? -0.3 : 0;
          this.shapes.push({
            name: isNext ? 'project_next' : 'project_' + i,
            scroll_inertia: 0,
            x: 0,
            y: 0,
            w: 0,
            h: 0,
            cover: nextProjectVideo,
            project: true,
            scroll_distortion: !isNext,
            $el: figure,
            id: this.shapes.length,
            mask: nextProjectVideo
              ? { t: [1, -0.61], c: [1, -0.61] }
              : { t: [1, 1], c: [1, 1] },
            z_index: this.shapes.length + zindex,
            render: false,
            show: isNext,
            ratio: ratio,
            isIn: false,
            texture: this.medias.length - 1,
            opacity: isNext || i === 0 ? { c: 1, t: 1 } : { c: 0, t: 0 },
            points: points,
            clear: true,
            lastState: {
              x: 0,
              y: 0,
              w: 0,
              h: 0,
            },
            lastV: null,
          });
          // media.remove();
        },
      );
        this.setPositions(next);
      */
    },
    setShapes() {
      const points = {
        x: 8,
        y: 16,
      };
      this.medias.forEach((media, i) => {
        const is_video = media.tagName !== 'IMG';
        const ratio = is_video
          ? media.videoWidth / media.videoHeight
          : media.naturalWidth / media.naturalHeight;
        this.shapes.push({
          name: media.dataset.name,
          x: 0,
          y: 0,
          w: 0,
          h: 0,
          show: true,
          cover: ratio < 1,
          header: media.name !== 'logo',
          video: media.tagName !== 'IMG',
          id: this.shapes.length,
          mask: { t: [is_video ? -1 : 1, 1], c: [is_video ? -1 : 1, 1] },
          z_index: this.shapes.length + 1,
          render: false,
          ratio: ratio,
          isIn: false,
          fixed: true,
          texture: this.shapes.length,
          opacity: { c: 0, t: 0 },
          points: points,
          lastState: {
            x: 0,
            y: 0,
            w: 0,
            h: 0,
          },
          lastV: null,
        });
      });

      this.shapes.push({
        name: 'video_transition',
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        show: true,
        cover: true,
        id: this.shapes.length,
        mask: { t: [1, -0.1], c: [1, -0.1] },
        z_index: this.shapes.length,
        render: false,
        ratio: 1.5,
        isIn: false,
        fixed: true,
        texture: this.shapes.length,
        opacity: { c: 0, t: 0 },
        points: points,
        lastState: {
          x: 0,
          y: 0,
          w: 0,
          h: 0,
        },
        lastV: null,
      });

      this.headerImage = this.shapes.find(s => s.name.indexOf('photo') !== -1);
      this.headerVideo = this.shapes.find(s => s.name.indexOf('film') !== -1);

      this.headerImageIndex = 0;
      this.headerVideoIndex = 0;

      this.headerImage.render = true;
      this.headerImage.opacity = this.isHome ? { c: 1, t: 1 } : { c: 0, t: 0 };
      if (!this.isMobile) this.headerImage.mask = { t: [0.5, 1], c: [0.5, 1] };
      this.headerImage.z_index = this.shapes.length;

      this.headerVideo.render = true;
      this.headerVideo.opacity = this.isHome ? { c: 1, t: 1 } : { c: 0, t: 0 };
      this.headerVideo.mask = { t: [-0.5, 1], c: [-0.5, 1] };
      this.headerVideo.z_index = this.shapes.length + 1;
      this.headerVideo.play = true;
    },
    setLogos() {
      if (this.logoShape) {
        this.logoShape.render = true;
        this.logoShape.opacityMultiplier = 0.4;
      }
    },
    setPositions(next) {
      if (this.shapes.length > 0) {
        this.shapes.forEach((shape, i) => {
          let x = shape.x;
          let y = shape.y;
          let w = shape.w;
          let h = shape.h;

          if (shape.name === 'video_transition') {
            w = document.querySelector('.main-wrapper').clientWidth - 20 * 2;
            h = window.innerHeight - 50 * 2;

            x = 20;
            y = 250;
          } else if (shape.name === 'logo_0') {
            const size = window.innerWidth >= 720 ? 0.5 : 0.75;
            w = document.querySelector('.main-wrapper').clientWidth * size;
            h = (w / 1200) * 210;
            shape.z_index = 999;

            x =
              document.querySelector('.main-wrapper').clientWidth * 0.5 -
              w * 0.5;
            y = window.innerHeight * 0.5 - h * 0.5;

            // console.log('logoshape', shape);
          } else if (shape.header) {
            const $c = this.$el;
            // const rect = $c.getBoundingClientRect();
            w = $c.clientWidth;
            h = $c.clientHeight;
            x = 0;
            y = 0;
            if (this.isMobile) {
              h *= 0.5;
              if (shape.name === 'film_0') y = h;
            }
          } else if (shape.$el) {
            const rect = shape.$el.getBoundingClientRect();
            const pos = Util.getPosition(shape.$el);
            w = rect.width;
            h = rect.height;

            x = pos.x;
            y = pos.y;
            if (next && shape.name === 'project_0') y += this.nextProjectOffset;
            if (shape.name === 'project_next') {
              y += this.$route.name === 'films-id' ? w * 0.12 : h * 0.5;
            }
            if (shape.show && shape.name !== 'project_next') {
              shape.y_target = y;
            }
          }

          shape.x = x;
          shape.y = y;
          shape.w = w;
          shape.h = h;

          shape.lastState.x = x;
          shape.lastState.y = y;
          shape.lastState.w = w;
          shape.lastState.h = h;
          shape.lastV = null;
        });
      }
      if (window.mainScroll) {
        window.mainScroll.pause = false;
      }
      this.handleScroll(this.scroll.c, this.scroll.max, next);
    },
    handleScroll(pos, max, next) {
      if (window.mainScroll) {
        if (next) {
          window.mainScroll.target = 0;
          window.mainScroll.data.t = 0;
          window.mainScroll.data.c = 0;
          const sections = Array.from(
            document.querySelectorAll('.scroll-section'),
          ).map($el => {
            return { $el: $el };
          });
          window.mainScroll.updateContent(
            document.querySelector('.scroll-page'),
            sections,
          );
          window.mainScroll.resize();
        }
      }
      this.shapes.forEach((shape, i) => {
        if (
          !shape.header &&
          shape.name !== 'video_transition' &&
          shape.name !== 'logo_0'
        ) {
          const scroll_inertia = shape.scroll_inertia || 0;
          const scroll = scroll_inertia
            ? this.scroll.c - scroll_inertia * this.scroll.c
            : this.scroll.c;
          if (
            shape.name === 'project_0' &&
            shape.show &&
            this.$route.name === 'films-id'
          ) {
            shape.render = true;
          } else {
            shape.render = Util.isInViewportGL(shape, scroll);
          }
          if (!shape.show && shape.render) {
            shape.show = true;
            // var y = {t: shape.y, c: shape.y + shape.h * 0.2};
            // TweenMax.fromTo(shape, 1, {y: y.c}, {y: y.t, ease: Power4.easeOut});
            shape.opacity.t = 1.0;
            if (shape.name !== 'project_next') {
              if (
                shape.name === 'project_0' &&
                this.$route.name === 'photos-id'
              ) {
                shape.y_target = next
                  ? shape.y - this.nextProjectOffset
                  : shape.y;
                shape.y = next ? shape.y : shape.y + shape.h * 2;
              } else {
                shape.y_target = shape.y;
                shape.y = shape.y + shape.h * 0.2;
              }
            }
          }
        }
        if (shape.name === 'video_transition') {
          // shape.render = shape.mask.c[1] < -0.1 && shape.opacity.c > 0;
        }
      });
      /*
      if (
        window.mainScroll &&
        window.mainScroll.onlyVirtual &&
        !window.mainScroll.pause
      ) {
        const firstShape = this.shapes.find(s => s.name === 'project_0') || {
          x: 0,
          y: 0,
        };
        const nextShape = this.shapes.find(s => s.name === 'project_next');
        const scroll = nextShape.scroll_inertia
          ? this.scroll.c - nextShape.scroll_inertia * this.scroll.c
          : this.scroll.c;
        let configNext = {
          x: { t: firstShape.x, c: nextShape.x },
          y: {
            t:
              nextShape.y -
              (nextShape.y - scroll) +
              firstShape.y +
              this.nextProjectOffset,
            c: nextShape.y,
          },
        };
        if (this.$route.name === 'films-id') {
          configNext = {
            y: {
              t:
                nextShape.y -
                (nextShape.y - scroll) +
                this.$el.clientHeight * 0.5 -
                nextShape.h * 0.5,
              c: nextShape.y,
            },
          };
        }
        if (window.mainScroll.maxVirtual === 0) {
          window.mainScroll.maxVirtual =
            window.mainScroll.max + configNext.y.c - configNext.y.t;
        } else if (
          configNext.y.t - configNext.y.c > 0 ||
          window.mainScroll.maxVirtual - window.mainScroll.data.c < 0.1
        ) {
          this.$root.$emit('hideCursorText');
          console.log('pause------');
          window.mainScroll.pause = true;
          const title = document.querySelector(
            '.next-project .big-title__photos, .next-project .big-title__films',
          );
          const clone = title.cloneNode(true);
          clone.setAttribute('id', 'next-title-clone');
          clone.removeAttribute('style');

          document
            .querySelector('.main-wrapper')
            .insertBefore(clone, document.querySelector('.canvas-container'));
          clone.style.zIndex = '12';
          title.style.opacity = 0;

          const currentTitle = document.querySelector(
            '.project-page .content .big-title__photos, .project-page .content .big-title__films',
          );
          if (currentTitle) {
            TweenMax.to(currentTitle, 1, {
              opacity: 0,
              y: '-100%',
              ease: Power4.easeInOut,
              onComplete: () => {
                this.$router.push({
                  path: this.nextProject.slug,
                  query: { next: 1 },
                });
              },
            });
            if (this.$route.name === 'films-id') {
              setTimeout(() => {
                nextShape.maskMultiplier = 0.05;
                nextShape.mask.t[1] = -1;
              }, 500);
            }
          } else {
            this.$router.push({
              path: this.nextProject.slug,
              query: { next: 1 },
            });
          }
        }
      } */
    },
    handleMouse(e) {
      if (this.ready) {
        this.mouseMoved = true;

        const data = e.touches ? e.touches[0] : e;
        this.mouseCache = {
          x: data.clientX,
          y: data.clientY,
        };
        if (
          !this.navOpen &&
          this.isHome &&
          !this.isMobile &&
          this.headerImage &&
          this.headerVideo
        ) {
          const prc =
            data.clientX / document.querySelector('.main-wrapper').clientWidth;
          if (prc < 0.15) {
            this.headerImage.mask.t[0] = 0.85;
            this.headerVideo.mask.t[0] = -0.15;
            this.hideSprite();
            this.$root.$emit('showMenuPhoto');
          } else if (prc > 0.85) {
            this.headerImage.mask.t[0] = 0.15;
            this.headerVideo.mask.t[0] = -0.85;
            this.hideSprite();
            this.$root.$emit('showMenuFilm');
          } else {
            this.showSprite();
            this.headerImage.mask.t[0] = 0.5;
            this.headerVideo.mask.t[0] = -0.5;
            this.$root.$emit('hideMenu');
          }
        }
      }
    },
    showResource(section, index, showTitle) {
      if (section === 'photos') {
        const active = this.shapes.filter(
          s => s.name.indexOf('film') === -1 && s.header,
        )[index];
        if (index !== this.headerImage) {
          this.headerImage.opacity.t = 0;
          //   active.z_index = this.shapes.length + 1;
          active.render = true;
          active.opacity.t = 1;
          this.headerImage = active;
          this.headerImageIndex = index;
        }
      } else if (section === 'films') {
        // console.log('show resource');
        const active = this.shapes.filter(
          s => s.name.indexOf('film') !== -1 && s.header,
        )[index];
        if (index !== this.headerVideo) {
          this.headerVideo.opacity.t = 0;
          this.headerVideo.play = false;
          //   active.z_index = this.shapes.length + 1;
          active.render = true;
          active.play = true;
          active.mask.c[0] = Number(String(this.headerVideo.mask.c[0]));
          active.mask.c[1] = Number(String(this.headerVideo.mask.c[1]));
          active.mask.t[0] = Number(String(this.headerVideo.mask.t[0]));
          active.mask.t[1] = Number(String(this.headerVideo.mask.t[1]));
          //   console.log('playing', active);
          active.opacity.t = 1;
          this.headerVideo = active;
          this.headerVideoIndex = index;
        }
      }
    },
    hideResource(section, current, next) {},
    showNav(section) {
      this.navOpen = true;
      //   if (this.isMobile) return;
      if (this.isHome) {
        if (section === 'photos') {
          this.headerImage.mask.t[0] = 1;
          this.headerVideo.mask.t[0] = -0.0001;
        } else {
          this.headerImage.mask.t[0] = -0.0001;
          this.headerVideo.mask.t[0] = -1;
        }
      } else {
        // this.headerImage.render = true;
        // this.headerVideo.render = true;
        // if (section === 'photos') {
        //   this.headerVideo.opacity.t = 0;
        //   this.headerVideo.opacity.c = 0;
        //   this.headerImage.mask.t[0] = 1;
        //   this.headerImage.mask.c[0] = 1;
        // } else {
        //   this.headerImage.opacity.t = 0;
        //   this.headerImage.opacity.c = 0;
        //   this.headerVideo.mask.t[0] = -1;
        //   this.headerVideo.mask.c[0] = -1;
        // }
        // this.shapes.forEach(s => {
        //   if (s.render && s.project) s.opacity.t = 0;
        // });
      }
    },
    hideNav(section, go) {
      this.navOpen = false;

      if (this.isHome) {
        // if (this.isMobile) {
        //   this.headerImage.opacity.t = 0;
        //   this.headerVideo.opacity.t = 0;
        // }
        this.showSprite();
        this.headerImage.mask.t[0] = 0.5;
        this.headerVideo.mask.t[0] = -0.5;
      } else {
        // if (
        //   this.currentType === 'films' &&
        //   document.querySelector('.single-header') &&
        //   go
        // ) {
        //   document.querySelector('.single-header').style.opacity = 0;
        // }
        // if (go && this.isMobile) {
        //   this.shapes.forEach(s => {
        //     if (s.render && s.project) s.opacity.c = 0;
        //     s.opacity.t = 0;
        //   });
        //   this.clearCanvas = true;
        // }
        // if (!this.isMobile) {
        //   this.headerImage.opacity.t = 0;
        //   this.headerVideo.opacity.t = 0;
        //   /* this.headerImage.render = false;
        //   this.headerVideo.render = false; */
        //   this.shapes.forEach(s => {
        //     if (s.render && s.project) s.opacity.t = 1;
        //   });
        // }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.canvas-container {
  position: fixed;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
  .canvas {
    width: 100%;
    height: 100%;
    background-color: black;
  }
  &.above-all {
    z-index: 12;
    .canvas {
      background-color: black;
    }
  }
}
</style>
