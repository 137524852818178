export default ({ app }, inject) => {
  inject('getMobileOS', () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
      return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS';
    }

    return 'unknown';
  });

  inject('mobileLink', {
    ios: 'https://apps.apple.com/us/app/spruce-care-messenger/id1080857171',
    android:
      'https://play.google.com/store/apps/details?id=com.spruce.messenger&hl=en_US',
  });
};
