import Util from '../assets/js/util';
export default {
  data: () => ({
    vertex: '',
    fragment: '',
    textures: [],
    shapes: [],
    medias: [],
    gl: null,
    isSwitching: false,
    now: 0,
    allDone: false,
    uniforms: {},
    attrs: {},
    matrix: null,
    winGL: 0,
    winGlDemi: 0,
    winGlwinW: 0,
    winGlwinH: 0,
    clearCanvas: false,
    color: { t: [0, 0, 0], c: [0, 0, 0] },
    scroll: { t: 0, c: 0 },
    scroll_delta: 0,
    noise_1: { t: 0.1, c: 0.1 },
    noise_2: { t: 0, c: 0 },
    isMobile: false, // document.querySelector('.main-wrapper').clientWidth < 840,
  }),
  methods: {
    setOptions(scroll) {
      this.gl =
        this.$refs.canvas.getContext('webgl', { premultipliedAlpha: false }) ||
        this.$refs.canvas.getContext('experimental-webgl', {
          premultipliedAlpha: false,
        });
      this.gl.clearColor(0, 0, 0, 1);
      this.gl.enable(this.gl.BLEND);
      this.gl.blendFunc(this.gl.ONE, this.gl.ONE_MINUS_SRC_ALPHA);

      this.isMobile = false; // document.querySelector('.main-wrapper').clientWidth < 840;

      this.scroll = scroll || { t: 0, c: 0 };

      this.startTime = new Date().getTime();
      this.now = this.startTime;

      const deg = (45 * Math.PI) / 180;
      this.matrix = Util.M.multiply(
        Util.M.perspective(deg, 1, 1, 10),
        Util.M.inverse(Util.M.lookAt([0, 0, 5], [0, 0, 0], [0, 1, 0])),
      );
      this.winGl = Util.round(10 * Math.tan(deg / 2), 5);

      this.winGlDemi = this.winGl / 2;
      this.winGlwinW =
        this.winGl / document.querySelector('.main-wrapper').clientWidth;
      this.winGlwinH = this.winGl / window.innerHeight;

      this.time_active = 0;
      this.mouseCache = {
        x: 0,
        y: 0,
      };

      this.createScene();
      this.uniforms.m.set(false, this.matrix);
      this.uniforms.w.set(this.winGl);

      this.setShapes();
      this.setLogos();
      this.setPositions();
      this.setTextures();

      this.render();
      setTimeout(() => {
        this.ready = true;
      }, 250);
    },
    createScene() {
      this.program = this.gl.createProgram();
      this.addShader(this.vertex, this.gl.VERTEX_SHADER, this.program);
      this.addShader(this.fragment, this.gl.FRAGMENT_SHADER, this.program);

      this.gl.linkProgram(this.program);
      this.gl.useProgram(this.program);

      this.uniforms = {
        resolution: new Util.Uniform('resolution', '2f', this.program, this.gl),
        img_resolution: new Util.Uniform(
          'img_resolution',
          '2f',
          this.program,
          this.gl,
        ),
        time: new Util.Uniform('time', '1f', this.program, this.gl),
        time_active: new Util.Uniform(
          'time_active',
          '1f',
          this.program,
          this.gl,
        ),
        mouse: new Util.Uniform('mouse', '2f', this.program, this.gl),
        mouse_lag: new Util.Uniform('mouse_lag', '2f', this.program, this.gl),
        color: new Util.Uniform('color', '3f', this.program, this.gl),
        shape_texture_scale: new Util.Uniform(
          'shape_texture_scale',
          '2f',
          this.program,
          this.gl,
        ),
        dpi: new Util.Uniform('dpi', '1f', this.program, this.gl),
        sp: new Util.Uniform('sp', '2f', this.program, this.gl),
        sw: new Util.Uniform('sw', '1f', this.program, this.gl),
        sh: new Util.Uniform('sh', '1f', this.program, this.gl),
        sy: new Util.Uniform('sy', '1f', this.program, this.gl),
        sx: new Util.Uniform('sx', '1f', this.program, this.gl),
        noise_1: new Util.Uniform('noise_1', '1f', this.program, this.gl),
        noise_2: new Util.Uniform('noise_2', '1f', this.program, this.gl),
        mask: new Util.Uniform('mask', '2f', this.program, this.gl),
        hascolor: new Util.Uniform('hascolor', '1i', this.program, this.gl),
        scolor: new Util.Uniform('scolor', '3f', this.program, this.gl),
        opacity: new Util.Uniform('opacity', '1f', this.program, this.gl),
        clear: new Util.Uniform('clear', '1f', this.program, this.gl),
        w: new Util.Uniform('w', '1f', this.program, this.gl),
        m: new Util.Uniform('m', 'Matrix4fv', this.program, this.gl),
        scroll: new Util.Uniform('scroll', '1f', this.program, this.gl),
        ratio: new Util.Uniform('pixelRatio', '1f', this.program, this.gl),
      };
      this.attrs = {
        position: new Util.Attr('position', this.program, this.gl),
        t: new Util.Attr('t', this.program, this.gl),
      };
      this.texCoordBuffer = this.gl.createBuffer();
      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texCoordBuffer);
      this.gl.vertexAttribPointer(
        this.attrs.t.get(),
        2,
        this.gl.FLOAT,
        !1,
        0,
        0,
      );

      this.positionBuffer = this.gl.createBuffer();
      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
      this.gl.vertexAttribPointer(
        this.attrs.position.get(),
        2,
        this.gl.FLOAT,
        !1,
        0,
        0,
      );
      this.gl.enableVertexAttribArray(this.attrs.position.get());

      this.createLogo();

      this.gl.useProgram(this.program);
      this.resize();
      this.setListeners();
    },
    createLogo() {
      // Create special shape for logo shader
      this.logoProgram = this.gl.createProgram();
      this.addShader(this.logo_vertex, this.gl.VERTEX_SHADER, this.logoProgram);
      this.addShader(
        this.logo_fragment,
        this.gl.FRAGMENT_SHADER,
        this.logoProgram,
      );

      this.gl.linkProgram(this.logoProgram);
      this.gl.useProgram(this.logoProgram);

      this.logoUniforms = {
        resolution: new Util.Uniform(
          'resolution',
          '2f',
          this.logoProgram,
          this.gl,
        ),
        img_resolution: new Util.Uniform(
          'img_resolution',
          '2f',
          this.logoProgram,
          this.gl,
        ),
        time: new Util.Uniform('time', '1f', this.logoProgram, this.gl),
        time_active: new Util.Uniform(
          'time_active',
          '1f',
          this.logoProgram,
          this.gl,
        ),
        // color: new Util.Uniform('color', '3f', this.logoProgram, this.gl),
        shape_texture_scale: new Util.Uniform(
          'shape_texture_scale',
          '2f',
          this.logoProgram,
          this.gl,
        ),
        mouse: new Util.Uniform('mouse', '2f', this.logoProgram, this.gl),
        mouse_lag: new Util.Uniform(
          'mouse_lag',
          '2f',
          this.logoProgram,
          this.gl,
        ),
        dpi: new Util.Uniform('dpi', '1f', this.logoProgram, this.gl),
        sp: new Util.Uniform('sp', '2f', this.logoProgram, this.gl),
        sw: new Util.Uniform('sw', '1f', this.logoProgram, this.gl),
        sh: new Util.Uniform('sh', '1f', this.logoProgram, this.gl),
        sy: new Util.Uniform('sy', '1f', this.logoProgram, this.gl),
        sx: new Util.Uniform('sx', '1f', this.logoProgram, this.gl),
        // noise_1: new Util.Uniform('noise_1', '1f', this.logoProgram, this.gl),
        // noise_2: new Util.Uniform('noise_2', '1f', this.logoProgram, this.gl),
        // mask: new Util.Uniform('mask', '2f', this.logoProgram, this.gl),
        // hascolor: new Util.Uniform('hascolor', '1i', this.logoProgram, this.gl),
        // scolor: new Util.Uniform('scolor', '3f', this.logoProgram, this.gl),
        opacity: new Util.Uniform('opacity', '1f', this.logoProgram, this.gl),
        clear: new Util.Uniform('clear', '1f', this.logoProgram, this.gl),
        w: new Util.Uniform('w', '1f', this.logoProgram, this.gl),
        m: new Util.Uniform('m', 'Matrix4fv', this.logoProgram, this.gl),
        // scroll: new Util.Uniform('scroll', '1f', this.logoProgram, this.gl),
        ratio: new Util.Uniform('pixelRatio', '1f', this.logoProgram, this.gl),
      };

      this.logoAttrs = {
        position: new Util.Attr('position', this.logoProgram, this.gl),
        t: new Util.Attr('t', this.logoProgram, this.gl),
      };
      //   this.logoTexCoordBuffer = this.gl.createBuffer();

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texCoordBuffer);
      this.gl.vertexAttribPointer(
        this.logoAttrs.t.get(),
        2,
        this.gl.FLOAT,
        !1,
        0,
        0,
      );

      //   this.logoPositionBuffer = this.gl.createBuffer();
      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
      this.gl.vertexAttribPointer(
        this.logoAttrs.position.get(),
        2,
        this.gl.FLOAT,
        !1,
        0,
        0,
      );

      this.gl.enableVertexAttribArray(this.attrs.position.get());

      this.logoUniforms.m.set(false, this.matrix);
      this.logoUniforms.w.set(this.winGl);
    },
    setListeners() {
      window.addEventListener('resize', () => {
        clearTimeout(this.resizedFinished);
        this.resizedFinished = setTimeout(() => {
          this.resize();
        }, 50);
      });
      //   document.addEventListener('mousemove', e => this.handleMouse(e));
    },
    addShader(source, type, program) {
      const shader = this.gl.createShader(type);
      this.gl.shaderSource(shader, source);
      this.gl.compileShader(shader);
      const isCompiled = this.gl.getShaderParameter(
        shader,
        this.gl.COMPILE_STATUS,
      );
      if (!isCompiled) {
        throw new Error(
          'Shader compile error: ' + this.gl.getShaderInfoLog(shader),
        );
      }
      this.gl.attachShader(program, shader);
    },
    setTextures() {
      for (let i = 0; i < this.medias.length; i++) {
        this.addTexture(this.medias[i]);
      }
    },
    addTexture(media) {
      const t = this.gl.createTexture();
      this.gl.bindTexture(this.gl.TEXTURE_2D, t);
      this.gl.texImage2D(
        this.gl.TEXTURE_2D,
        0,
        this.gl.RGBA,
        this.gl.RGBA,
        this.gl.UNSIGNED_BYTE,
        media,
      );
      this.gl.texParameteri(
        this.gl.TEXTURE_2D,
        this.gl.TEXTURE_WRAP_S,
        this.gl.CLAMP_TO_EDGE,
      );
      this.gl.texParameteri(
        this.gl.TEXTURE_2D,
        this.gl.TEXTURE_WRAP_T,
        this.gl.CLAMP_TO_EDGE,
      );
      this.gl.texParameteri(
        this.gl.TEXTURE_2D,
        this.gl.TEXTURE_MIN_FILTER,
        this.gl.LINEAR,
      );
      this.gl.texParameteri(
        this.gl.TEXTURE_2D,
        this.gl.TEXTURE_MAG_FILTER,
        this.gl.LINEAR,
      );
      this.textures.push(t);
    },
    updateTexture(index, src) {
      const t = this.textures[index];
      this.gl.bindTexture(this.gl.TEXTURE_2D, t);
      this.gl.texImage2D(
        this.gl.TEXTURE_2D,
        0,
        this.gl.RGBA,
        this.gl.RGBA,
        this.gl.UNSIGNED_BYTE,
        src,
      );
      this.gl.texParameteri(
        this.gl.TEXTURE_2D,
        this.gl.TEXTURE_WRAP_S,
        this.gl.CLAMP_TO_EDGE,
      );
      this.gl.texParameteri(
        this.gl.TEXTURE_2D,
        this.gl.TEXTURE_WRAP_T,
        this.gl.CLAMP_TO_EDGE,
      );
      this.gl.texParameteri(
        this.gl.TEXTURE_2D,
        this.gl.TEXTURE_MIN_FILTER,
        this.gl.LINEAR,
      );
      this.gl.texParameteri(
        this.gl.TEXTURE_2D,
        this.gl.TEXTURE_MAG_FILTER,
        this.gl.LINEAR,
      );
    },
    drawLogo(index) {
      this.gl.useProgram(this.logoProgram);
      this.logoUniforms.time.set(this.now);
      this.logoUniforms.time_active.set(this.time_active);
      const texNo = this.shapes[index].texture;
      const shape = this.shapes[index];
      const media = this.medias[texNo];
      if (
        !shape.lastV ||
        (!shape.fixed &&
          (shape.lastState.x !== shape.x ||
            shape.lastState.y !== shape.y ||
            shape.lastState.w !== shape.w ||
            shape.lastState.h !== shape.h)) ||
        (shape.fixed &&
          (shape.lastState.x !== shape.x ||
            shape.lastState.y !== shape.y ||
            shape.lastState.w !== shape.w ||
            shape.lastState.h !== shape.h))
      ) {
        shape.lastV = this.vertices({
          shape: shape,
          scroll: 0,
          media: media,
        });
      }
      //   console.log(shape.lastV);
      this.logoUniforms.mouse.set(this.mouseCache.x, this.mouseCache.y);
      this.logoUniforms.mouse_lag.set(
        this.lag.buffer[0][0],
        this.lag.buffer[0][1],
      );
      this.logoUniforms.img_resolution.set(shape.w, shape.h);
      this.logoUniforms.sh.set(shape.lastV.shapeH);
      this.logoUniforms.sw.set(shape.lastV.shapeW);
      this.logoUniforms.sy.set(shape.lastV.shapeY);
      this.logoUniforms.sp.set(shape.x, shape.y);
      this.logoUniforms.shape_texture_scale.set(
        shape.lastV.shapeTextureScale.w,
        shape.lastV.shapeTextureScale.h,
      );

      this.logoUniforms.opacity.set(shape.opacity.c);

      this.gl.activeTexture(this.gl.TEXTURE0);
      this.gl.bindTexture(this.gl.TEXTURE_2D, this.textures[texNo]);
      this.gl.activeTexture(this.gl.TEXTURE1);
      this.gl.bindTexture(
        this.gl.TEXTURE_2D,
        this.textures[texNo + this.textures.length],
      );

      // dont do this... wtf is all that data anyways?
      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texCoordBuffer);
      this.gl.enableVertexAttribArray(this.logoAttrs.t.get());
      this.gl.bufferData(
        this.gl.ARRAY_BUFFER,
        // new Float32Array([0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 1.0, 1.0]),
        new Float32Array(shape.lastV.texture),
        this.gl.STATIC_DRAW,
      );
      //   console.log(shape.lastV.data);

      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
      this.gl.enableVertexAttribArray(this.logoAttrs.position.get());
      this.gl.bufferData(
        this.gl.ARRAY_BUFFER,
        // new Float32Array([0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 1.0, 1.0]),
        new Float32Array(shape.lastV.data),
        this.gl.STATIC_DRAW,
      );
      this.gl.drawArrays(this.gl.TRIANGLES, 0, shape.lastV.length);
    },
    draw(index) {
      const texNo = this.shapes[index].texture;
      const shape = this.shapes[index];
      const media = this.medias[texNo];
      const scroll = shape.scroll_inertia
        ? -this.scroll.c + shape.scroll_inertia * this.scroll.c
        : -this.scroll.c;

      if (shape.video) {
        if (shape.play && shape.opacity.c > 0.01) {
          media.play();
        } else {
          media.pause();
        }
        this.updateTexture(texNo, media);
      }
      if (
        !shape.lastV ||
        (!shape.fixed &&
          (shape.lastState.x !== shape.x ||
            shape.lastState.y !== shape.y ||
            shape.lastState.w !== shape.w ||
            shape.lastState.h !== shape.h)) ||
        (shape.fixed &&
          (shape.lastState.x !== shape.x ||
            shape.lastState.y !== shape.y ||
            shape.lastState.w !== shape.w ||
            shape.lastState.h !== shape.h))
      ) {
        shape.lastV = this.vertices({
          shape: shape,
          scroll: shape.fixed ? 0 : scroll,
          media: media,
        });
      } else if (
        !shape.fixed &&
        shape.lastState.x === shape.x &&
        shape.lastState.y === shape.y &&
        shape.lastState.w === shape.w &&
        shape.lastState.h === shape.h
      ) {
        const v = this.updateYV({
          shape: shape,
          scroll: scroll,
        });
        shape.lastV.data = v.data;
        shape.lastV.data = v.data;
        shape.lastV.length = v.length;
        shape.lastV.shapeY = v.shapeY;
        shape.lastV.shapeW = v.shapeW;
        shape.lastV.shapeH = v.shapeH;
      }
      this.uniforms.img_resolution.set(shape.w, shape.h);
      this.uniforms.sh.set(shape.lastV.shapeH);
      this.uniforms.sw.set(shape.lastV.shapeW);
      this.uniforms.sy.set(shape.lastV.shapeY);
      this.uniforms.sp.set(shape.x, shape.y);
      this.uniforms.shape_texture_scale.set(
        shape.lastV.shapeTextureScale.w,
        shape.lastV.shapeTextureScale.h,
      );

      this.uniforms.mask.set(...shape.mask.c);
      this.uniforms.opacity.set(shape.opacity.c);
      this.uniforms.hascolor.set(shape.color !== undefined);
      this.uniforms.noise_1.set(shape.clear ? 0 : this.noise_1.c);
      this.uniforms.noise_2.set(shape.clear ? 0 : this.noise_2.c);

      if (shape.color) {
        this.uniforms.scolor.set(...shape.color.c);
      } else {
        this.gl.activeTexture(this.gl.TEXTURE0);
        this.gl.bindTexture(this.gl.TEXTURE_2D, this.textures[texNo]);
        this.gl.activeTexture(this.gl.TEXTURE1);
        this.gl.bindTexture(
          this.gl.TEXTURE_2D,
          this.textures[texNo + this.textures.length],
        );
        this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.texCoordBuffer);
        this.gl.enableVertexAttribArray(this.attrs.t.get());
        this.gl.bufferData(
          this.gl.ARRAY_BUFFER,
          new Float32Array(shape.lastV.texture),
          this.gl.STATIC_DRAW,
        );
      }
      this.gl.bindBuffer(this.gl.ARRAY_BUFFER, this.positionBuffer);
      this.gl.enableVertexAttribArray(this.attrs.position.get());
      this.gl.bufferData(
        this.gl.ARRAY_BUFFER,
        new Float32Array(shape.lastV.data),
        this.gl.STATIC_DRAW,
      );
      this.gl.drawArrays(this.gl.TRIANGLES, 0, shape.lastV.length);
    },
    updateYV(config) {
      const shape = {
        x: config.shape.x * this.winGlwinW,
        y: (config.shape.y + config.scroll) * this.winGlwinH,
        w: config.shape.w * this.winGlwinW,
        h: config.shape.h * this.winGlwinH,
      };
      const ptx = config.shape.points.x - 1;
      const pty = config.shape.points.y - 1;
      const u = shape.w / ptx;
      const c = shape.h / pty;

      const f = -this.winGlDemi + shape.h;
      const v = this.winGlDemi - shape.x;
      const shapeY = f + shape.y;
      const m = [];
      let g = 0;

      for (let y = 0; y < pty; y++) {
        const w = c * y - shapeY;
        const x = w + c;
        for (let b = 0; b < ptx; b++) {
          const k = u * b - v;
          const L = k + u;
          m[g++] = k;
          m[g++] = w;
          m[g++] = L;
          m[g++] = w;
          m[g++] = k;
          m[g++] = x;
          m[g++] = k;
          m[g++] = x;
          m[g++] = L;
          m[g++] = w;
          m[g++] = L;
          m[g++] = x;
        }
      }
      return {
        data: m,
        length: m.length / 2,
        shapeW: shape.w,
        shapeH: shape.h,
        shapeY: -shapeY,
      };
    },
    vertices(config) {
      const shape = {
        x: config.shape.x * this.winGlwinW,
        y: (config.shape.y + config.scroll) * this.winGlwinH,
        w: config.shape.w * this.winGlwinW,
        h: config.shape.h * this.winGlwinH,
      };
      const ptx = config.shape.points.x - 1;
      const pty = config.shape.points.y - 1;
      const u = shape.w / ptx;
      const c = shape.h / pty;

      const f = -this.winGlDemi + shape.h;
      const v = this.winGlDemi - shape.x;
      const shapeY = f + shape.y;
      const shapeX = v + shape.x;
      const m = [];
      let g = 0;

      for (let y = 0; y < pty; y++) {
        const w = c * y - shapeY;
        const x = w + c;
        for (let b = 0; b < ptx; b++) {
          const k = u * b - v;
          const L = k + u;
          m[g++] = k;
          m[g++] = w;
          m[g++] = L;
          m[g++] = w;
          m[g++] = k;
          m[g++] = x;
          m[g++] = k;
          m[g++] = x;
          m[g++] = L;
          m[g++] = w;
          m[g++] = L;
          m[g++] = x;
        }
      }
      let media;
      if (config.media) {
        media = config.shape.video
          ? { width: config.media.videoWidth, height: config.media.videoHeight }
          : config.media;
      } else {
        media = { width: shape.w, height: shape.h };
      }

      const texture = [];
      const ratios = {};

      ratios.media = Util.round(media.width / media.height, 7);
      ratios.shape = Util.round(shape.w / shape.h, 7);

      const scale = {};
      scale.w = media.width / config.shape.w / (media.height / config.shape.h);
      scale.h = 1;
      if (config.media) {
        if (ratios.shape < ratios.media || config.shape.cover) {
          const r =
            media.height / config.shape.h / (media.width / config.shape.w);
          if (r < 1) {
            scale.w =
              media.width / config.shape.w / (media.height / config.shape.h);
            scale.h = 1;
          } else {
            scale.w = 1;
            scale.h = r;
          }
        }
      }

      const C = (1 - 1 / scale.w) / 2;
      const D = (1 - 1 / scale.h) / 2;
      let jj = 0;
      for (let j = 0; j < pty; j++) {
        const E = 1 - j / pty / scale.h - D;
        const $ = 1 - (j + 1) / pty / scale.h - D;
        for (let jjj = 0; jjj < ptx; jjj++) {
          const N = jjj / ptx / scale.w + C;
          const P = (jjj + 1) / ptx / scale.w + C;

          texture[jj++] = N;
          texture[jj++] = E;
          texture[jj++] = P;
          texture[jj++] = E;
          texture[jj++] = N;
          texture[jj++] = $;
          texture[jj++] = N;
          texture[jj++] = $;
          texture[jj++] = P;
          texture[jj++] = E;
          texture[jj++] = P;
          texture[jj++] = $;
        }
      }
      const output = {
        data: m,
        length: m.length / 2,
        shapeW: shape.w,
        shapeH: shape.h,
        shapeTextureScale: scale,
        shapeY: -shapeY,
        shapeX: shapeX,
        texture: texture,
      };

      return output;
    },
    resize() {
      //   console.log('resize');

      const dpr = window.devicePixelRatio || 1;
      const w = dpr * document.querySelector('.main-wrapper').clientWidth;
      const h = dpr * window.innerHeight;

      this.isMobile = false; // document.querySelector('.main-wrapper').clientWidth < 840;

      this.winGlwinW =
        this.winGl / document.querySelector('.main-wrapper').clientWidth;
      this.winGlwinH = this.winGl / window.innerHeight;

      this.$refs.canvas.width = w; // this.$el.clientWidth;
      this.$refs.canvas.height = h; // this.$el.clientHeight;

      this.gl.viewport(0, 0, this.$refs.canvas.width, this.$refs.canvas.height);

      this.gl.useProgram(this.logoProgram);
      this.logoUniforms.resolution.set(
        this.$refs.canvas.width / dpr,
        this.$refs.canvas.height / dpr,
      );
      this.logoUniforms.dpi.set(dpr);

      this.gl.useProgram(this.program);
      this.uniforms.resolution.set(
        this.$refs.canvas.width / dpr,
        this.$refs.canvas.height / dpr,
      );
      this.uniforms.dpi.set(dpr);
      this.setPositions();
    },
    render(t) {
      if (this.mouseMoved) {
        this.time_active += 1 / 60;
      }

      this.lag.tick(this.mouseCache);

      this.gl.useProgram(this.program);
      this.now = t / 1000;

      this.uniforms.time.set(this.now);
      this.uniforms.time_active.set(this.time_active);
      this.uniforms.color.set(...this.color.c);
      this.uniforms.scroll.set(-this.scroll.c);
      this.uniforms.mouse.set(this.mouseCache.x, this.mouseCache.y);
      this.uniforms.mouse_lag.set(this.lag.buffer[0][0], this.lag.buffer[0][1]);

      this.allDone = true;
      this.shapes.forEach((s, i) => {
        if (s.render) {
          if (s.mask.c[0] !== s.mask.t[0]) {
            const delta = s.mask.t[0] - s.mask.c[0];
            s.mask.c[0] += delta * (s.maskMultiplier ? s.maskMultiplier : 0.2);
            this.allDone = Math.abs(delta) < 0.5;
          }

          if (s.mask.c[1] !== s.mask.t[1]) {
            const delta = s.mask.t[1] - s.mask.c[1];
            s.mask.c[1] += delta * (s.maskMultiplier ? s.maskMultiplier : 0.2);
            this.allDone = Math.abs(delta) < 0.5;
          }

          if (s.color) {
            if (s.color.c[0] !== s.color.t[0]) {
              s.color.c[0] += (s.color.t[0] - s.color.c[0]) * 0.019;
            }
            if (s.color.c[1] !== s.color.t[1]) {
              s.color.c[1] += (s.color.t[1] - s.color.c[1]) * 0.019;
            }
            if (s.color.c[2] !== s.color.t[2]) {
              s.color.c[2] += (s.color.t[2] - s.color.c[2]) * 0.019;
            }
          }
          if (s.y_target && s.y_target !== s.y) {
            const delta = s.y_target - s.y;
            s.y += delta * 0.119;
            this.allDone = Math.abs(delta) < 0.5;
          }
          if (s.x_target && s.x_target !== s.x) {
            const delta = s.x_target - s.x;
            s.x += delta * 0.119;
            this.allDone = Math.abs(delta) < 0.5;
          }

          if (s.opacity.c !== s.opacity.t) {
            const delta = s.opacity.t - s.opacity.c;
            s.opacity.c =
              s.opacity.c +
              delta * (s.opacityMultiplier ? s.opacityMultiplier : 0.3);
            this.allDone = Math.abs(delta) < 0.5;
          }
        }

        {
          const index = this.headerVideoIndex;
          const id = this.headerVideo.id;
          const texNo = this.shapes[id].texture;
          const headerVideoMedia = this.medias[texNo];
          const tail = 2 / 24;
          const t = headerVideoMedia.currentTime;
          const next = t > headerVideoMedia.duration - tail;
          if (
            !this.isSwitching &&
            headerVideoMedia &&
            typeof headerVideoMedia.duration === 'number' &&
            headerVideoMedia.duration > 3 &&
            typeof t === 'number' &&
            t > 3 &&
            !this.nav_hovering &&
            next
          ) {
            this.isSwitching = true;
            setTimeout(() => {
              this.isSwitching = false;
            }, 1000);
            const count = Array.from(
              document.querySelectorAll('.main-nav__link.nav-right li'),
            ).length;
            const j = index + 1 >= count ? 0 : index + 1;
            headerVideoMedia.currentTime = 0;
            this.$root.$emit('showResource', j);
            if (this.$parent.isTouch) {
              this.$root.$emit('glitchLogo');
            }
          }
        }
      });
      const index = Array.prototype.slice
        .call(this.shapes)
        .filter(s => {
          return s.name !== 'logo_0';
        })
        .sort((a, b) => {
          return a.z_index - b.z_index;
        })
        .map(s => s.id);
      index.forEach(i => {
        const s = this.shapes[i];
        if (s.render && s.opacity.c > 0.05) this.draw(i);
      });

      const logo = Array.prototype.slice
        .call(this.shapes)
        .filter(s => {
          return s.name === 'logo_0';
        })
        .map(s => s.id);
      if (logo[0] !== undefined) {
        this.drawLogo(logo[0]);
      }

      if (this.clearCanvas) {
        this.clearCanvas = false;
        this.gl.clear(this.gl.DEPTH_BUFFER_BIT | this.gl.COLOR_BUFFER_BIT);
      }
      this.mouseMoved = false;
      requestAnimationFrame(t => this.render(t));
    },
  },
};
