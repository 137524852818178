function isInViewport(obj) {
  if (isElement(obj)) return isInViewportDom(obj);
  else return isInViewportGL(obj);
}

function isTouch() {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
}

function isInViewportGL(rect, scroll) {
  const x = rect.x;
  const y = rect.y + (scroll ? -scroll : 0);
  const w = rect.w;
  const h = rect.h;

  const el = document.querySelector('.main-wrapper');

  const ww = el.clientWidth;
  const hw = el.clientHeight;
  return y < hw && y + h > 0 && (x < ww && x + w > 0);
}
function isInViewportDom($el) {
  const rect = $el.getBoundingClientRect();
  //   let x, y, w, h;
  const x = rect.left;
  const y = rect.top;

  const w = rect.width;
  const h = rect.height;

  const el = document.querySelector('.main-wrapper');

  const ww = el.clientWidth;
  const hw = el.clientHeight;
  return y < hw && y + h > 0 && (x < ww && x + w > 0);
}
function isElement(obj) {
  try {
    // Using W3 DOM2 (works for FF, Opera and Chrome)
    return obj instanceof HTMLElement;
  } catch (e) {
    // Browsers not supporting W3 DOM2 don't have HTMLElement and
    // an exception is thrown and we end up here. Testing some
    // properties that all elements have (works on IE7)
    return (
      typeof obj === 'object' &&
      obj.nodeType === 1 &&
      typeof obj.style === 'object' &&
      typeof obj.ownerDocument === 'object'
    );
  }
}
function getPosition(element) {
  let xPosition = 0;
  let yPosition = 0;

  while (element) {
    xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
    yPosition += element.offsetTop - element.scrollTop + element.clientTop;
    element = element.offsetParent;
  }

  return { x: xPosition, y: yPosition };
}
function round(number, r_in) {
  const r = r_in ? Math.pow(10, r_in) : 1e3;
  return Math.round(number * r) / r;
}
function lerp(from, to, t) {
  return (1 - t) * from + t * to;
}
const Matrix = {
  inverse: function(t, i) {
    i = i || new Float32Array(16);
    const s = t[0];
    const e = t[1];
    const r = t[2];
    const n = t[3];
    const o = t[4];
    const a = t[5];
    const h = t[6];
    const l = t[7];
    const u = t[8];
    const c = t[9];
    const p = t[10];
    const f = t[11];
    const v = t[12];
    const d = t[13];
    const m = t[14];
    const g = t[15];
    const y = p * g;
    const w = m * f;
    const x = h * g;
    const b = m * l;
    const k = h * f;
    const L = p * l;
    const T = r * g;
    const M = m * n;
    const R = r * f;
    const C = p * n;
    const D = r * l;
    const A = h * n;
    const z = u * d;
    const W = v * c;
    const G = o * d;
    const S = v * a;
    const E = o * c;
    const _ = u * a;
    const H = s * d;
    const N = v * e;
    const P = s * c;
    const I = u * e;
    const O = s * a;
    const B = o * e;
    const F = y * a + b * c + k * d - (w * a + x * c + L * d);
    const U = w * e + T * c + C * d - (y * e + M * c + R * d);
    const Y = x * e + M * a + D * d - (b * e + T * a + A * d);
    const X = L * e + R * a + A * c - (k * e + C * a + D * c);
    const q = 1 / (s * F + o * U + u * Y + v * X);
    return (
      (i[0] = q * F),
      (i[1] = q * U),
      (i[2] = q * Y),
      (i[3] = q * X),
      (i[4] = q * (w * o + x * u + L * v - (y * o + b * u + k * v))),
      (i[5] = q * (y * s + M * u + R * v - (w * s + T * u + C * v))),
      (i[6] = q * (b * s + T * o + A * v - (x * s + M * o + D * v))),
      (i[7] = q * (k * s + C * o + D * u - (L * s + R * o + A * u))),
      (i[8] = q * (z * l + S * f + E * g - (W * l + G * f + _ * g))),
      (i[9] = q * (W * n + H * f + I * g - (z * n + N * f + P * g))),
      (i[10] = q * (G * n + N * l + O * g - (S * n + H * l + B * g))),
      (i[11] = q * (_ * n + P * l + B * f - (E * n + I * l + O * f))),
      (i[12] = q * (G * p + _ * m + W * h - (E * m + z * h + S * p))),
      (i[13] = q * (P * m + z * r + N * p - (H * p + I * m + W * r))),
      (i[14] = q * (H * h + B * m + S * r - (O * m + G * r + N * h))),
      (i[15] = q * (O * p + E * r + I * h - (P * h + B * p + _ * r))),
      i
    );
  },
  perspective: function(t, i, s, e, r) {
    r = r || new Float32Array(16);
    const n = Math.tan(0.5 * Math.PI - 0.5 * t);
    const o = 1 / (s - e);
    return (
      (r[0] = n / i),
      (r[1] = 0),
      (r[2] = 0),
      (r[3] = 0),
      (r[4] = 0),
      (r[5] = n),
      (r[6] = 0),
      (r[7] = 0),
      (r[8] = 0),
      (r[9] = 0),
      (r[10] = (s + e) * o),
      (r[11] = -1),
      (r[12] = 0),
      (r[13] = 0),
      (r[14] = s * e * o * 2),
      (r[15] = 0),
      r
    );
  },
  multiply: function(t, i, s) {
    s = s || new Float32Array(16);
    const e = i[0];
    const r = i[1];
    const n = i[2];
    const o = i[3];
    const a = i[4];
    const h = i[5];
    const l = i[6];
    const u = i[7];
    const c = i[8];
    const p = i[9];
    const f = i[10];
    const v = i[11];
    const d = i[12];
    const m = i[13];
    const g = i[14];
    const y = i[15];
    const w = t[0];
    const x = t[1];
    const b = t[2];
    const k = t[3];
    const L = t[4];
    const T = t[5];
    const M = t[6];
    const R = t[7];
    const C = t[8];
    const D = t[9];
    const A = t[10];
    const z = t[11];
    const W = t[12];
    const G = t[13];
    const S = t[14];
    const E = t[15];
    return (
      (s[0] = e * w + r * L + n * C + o * W),
      (s[1] = e * x + r * T + n * D + o * G),
      (s[2] = e * b + r * M + n * A + o * S),
      (s[3] = e * k + r * R + n * z + o * E),
      (s[4] = a * w + h * L + l * C + u * W),
      (s[5] = a * x + h * T + l * D + u * G),
      (s[6] = a * b + h * M + l * A + u * S),
      (s[7] = a * k + h * R + l * z + u * E),
      (s[8] = c * w + p * L + f * C + v * W),
      (s[9] = c * x + p * T + f * D + v * G),
      (s[10] = c * b + p * M + f * A + v * S),
      (s[11] = c * k + p * R + f * z + v * E),
      (s[12] = d * w + m * L + g * C + y * W),
      (s[13] = d * x + m * T + g * D + y * G),
      (s[14] = d * b + m * M + g * A + y * S),
      (s[15] = d * k + m * R + g * z + y * E),
      s
    );
  },
  lookAt: function(t, i, s, e) {
    e = e || new Float32Array(16);
    const r = this.normalize(this.subtract(t, i));
    const n = this.normalize(this.cross(s, r));
    const o = this.normalize(this.cross(r, n));
    return (
      (e[0] = n[0]),
      (e[1] = n[1]),
      (e[2] = n[2]),
      (e[3] = 0),
      (e[4] = o[0]),
      (e[5] = o[1]),
      (e[6] = o[2]),
      (e[7] = 0),
      (e[8] = r[0]),
      (e[9] = r[1]),
      (e[10] = r[2]),
      (e[11] = 0),
      (e[12] = t[0]),
      (e[13] = t[1]),
      (e[14] = t[2]),
      (e[15] = 1),
      e
    );
  },
  subtract: function(t, i, s) {
    return (
      ((s = s || new Float32Array(3))[0] = t[0] - i[0]),
      (s[1] = t[1] - i[1]),
      (s[2] = t[2] - i[2]),
      s
    );
  },
  cross: function(t, i, s) {
    return (
      ((s = s || new Float32Array(3))[0] = t[1] * i[2] - t[2] * i[1]),
      (s[1] = t[2] * i[0] - t[0] * i[2]),
      (s[2] = t[0] * i[1] - t[1] * i[0]),
      s
    );
  },
  normalize: function(t, i) {
    i = i || new Float32Array(3);
    const s = Math.sqrt(t[0] * t[0] + t[1] * t[1] + t[2] * t[2]);
    return (
      s > 1e-5 && ((i[0] = t[0] / s), (i[1] = t[1] / s), (i[2] = t[2] / s)), i
    );
  },
};
const ease = {
  bounce: function(t) {
    const p = 0.3;
    return (
      Math.pow(2, -10 * t) * Math.sin(((t - p / 4) * (2 * Math.PI)) / p) + 1
    );
  },
  out: function(t) {
    return 1 - --t * t * t * t;
  },
  inOut: function(t) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
  },
};
function testBrowser(browser) {
  let result;

  switch (browser) {
    case 'safari':
      result =
        typeof window.safari !== 'undefined' && window.safari.pushNotification;
      break;
    case 'safari mobile':
      result =
        (/iPhone/i.test(navigator.userAgent) ||
          (/Macintosh/.test(navigator.userAgent) &&
            'ontouchend' in document)) &&
        (/Safari/i.test(navigator.userAgent) ||
          /Instagram/i.test(navigator.userAgent));
      break;
    case 'samsung':
      result = /SamsungBrowser/.test(navigator.userAgent);
      break;
    case 'chrome':
      result =
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor) &&
        !/SamsungBrowser/.test(navigator.userAgent);
      break;
    case 'chrome mobile':
      result =
        /Chrome/.test(navigator.userAgent) &&
        /Google Inc/.test(navigator.vendor) &&
        !/SamsungBrowser/.test(navigator.userAgent) &&
        window.chrome &&
        !window.chrome.webstore;
      break;
    case 'firefox mobile':
      result =
        !/Chrome/.test(navigator.userAgent) &&
        /Mozilla/.test(navigator.userAgent) &&
        /Firefox/.test(navigator.userAgent) &&
        /Mobile/.test(navigator.userAgent);
      break;
    case 'firefox':
      result =
        !/Chrome/.test(navigator.userAgent) &&
        /Mozilla/.test(navigator.userAgent) &&
        /Firefox/.test(navigator.userAgent);
      break;
    case 'ie':
      result =
        /MSIE/.test(window.navigator.userAgent) ||
        /NET/.test(window.navigator.userAgent);
      break;
    case 'edge':
      result = /Edge/.test(window.navigator.userAgent);
      break;
    case 'ms':
      result =
        /Edge/.test(window.navigator.userAgent) ||
        /MSIE/.test(window.navigator.userAgent) ||
        /NET/.test(window.navigator.userAgent);
      break;
    default:
      result = false;
      break;
  }
  return result;
}
function loadImage(url, callback) {
  const image = new Image();
  image.src = url;
  image.onload = callback;
  return image;
}
function loadImages(urls, callback) {
  const images = [];
  let imagesToLoad = urls.length;
  const onImageLoad = function() {
    --imagesToLoad;
    if (imagesToLoad === 0) {
      callback(images);
    }
  };
  for (let ii = 0; ii < imagesToLoad; ++ii) {
    const image = loadImage(urls[ii], onImageLoad);
    images.push(image);
  }
}
function Uniform(name, suffix, program, gl) {
  name = 'u_' + name;
  this.name = name;
  this.suffix = suffix;
  this.gl = gl;
  this.program = program;
  this.location = gl.getUniformLocation(program, name);
}
Uniform.prototype.set = function(...values) {
  const method = 'uniform' + this.suffix;
  const args = [this.location].concat(values);
  this.gl[method].apply(this.gl, args);
};
Uniform.prototype.get = function() {
  return this.location;
};

function Attr(name, program, gl) {
  name = 'a_' + name;
  this.name = name;
  this.gl = gl;
  this.program = program;
  this.location = gl.getAttribLocation(program, name);
}
Attr.prototype.get = function() {
  return this.location;
};
export default {
  isTouch: isTouch,
  isInViewport: isInViewport,
  isInViewportGL: isInViewportGL,
  isInViewportDom: isInViewportDom,
  isElement: isElement,
  getPosition: getPosition,
  testBrowser: testBrowser,
  M: Matrix,
  ease: ease,
  round: round,
  lerp: lerp,
  loadImages: loadImages,
  loadImage: loadImage,
  Uniform: Uniform,
  Attr: Attr,
};
