import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _fb01887a = () => interopDefault(import('../pages/grid.vue' /* webpackChunkName: "pages/grid" */))
const _7a25212b = () => interopDefault(import('../pages/info.vue' /* webpackChunkName: "pages/info" */))
const _0e9ef226 = () => interopDefault(import('../pages/films/_id.vue' /* webpackChunkName: "pages/films/_id" */))
const _3c0e7ce9 = () => interopDefault(import('../pages/photos/_id.vue' /* webpackChunkName: "pages/photos/_id" */))
const _53942e25 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

const scrollBehavior = (from, to) => {
      return { x: 0, y: 0 };
    }

export function createRouter() {
  return new Router({
    mode: 'history',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/grid",
      component: _fb01887a,
      name: "grid"
    }, {
      path: "/info",
      component: _7a25212b,
      name: "info"
    }, {
      path: "/films/:id?",
      component: _0e9ef226,
      name: "films-id"
    }, {
      path: "/photos/:id?",
      component: _3c0e7ce9,
      name: "photos-id"
    }, {
      path: "/",
      component: _53942e25,
      name: "index"
    }],

    fallback: false
  })
}
