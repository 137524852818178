<template>
  <div v-if="loading" class="loading-bar-wrap">
    <div class="loading-bar" :style="{width: `${progress}%`}" />
  </div>
</template>

<script>
import { TweenLite, Power4 } from 'gsap';

export default {
  props: ['progress'],
  data: () => ({
    loading: false,
  }),
  methods: {
    start() {
      this.loading = true;
    },
    finish() {
      setTimeout(() => {
        TweenLite.to('.loading-bar', 0.5, {
          //   opacity: 0,
          scaleX: 0,
          transformOrigin: '100% 50%',
          ease: Power4.easeInOut,
          onComplete: () => {
            this.loading = false;
          },
        });
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-bar-wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: calc(var(--scrollwidth, 0));
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: 28%;
  padding-right: 28%;
  z-index: 31;
  //   background-color: #fff;

  .loading-bar {
    background-color: rgb(62, 210, 62);
    @extend .greenish;
    transition: width 0.1s ease-in-out;
    width: 50%;
    height: 2px;
    margin-top: 6vw;
  }
  @media screen and (max-width: 720px) {
    padding-left: 18%;
    padding-right: 18%;
    margin-top: 6.5vw;
  }
}
</style>
